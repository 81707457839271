.desktop-my-account {
  align-items: flex-start;
  display: flex;
  height: 1421px;
  overflow: hidden;
  width: 1920px;
}

.overlap-group5-2 {
  height: 1439px;
  position: relative;
  width: 1920px;
}

.user-no-sub {
  font-family: Montserrat;
  width: 100%;
  text-align: center;
}

.container-my-account {
  margin: 40px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.estiloLink{
  text-decoration: none !important;
}
.without-background {
  background-color: #fff !important;
}

.changeName{
  width: 100% !important;
  text-align: left !important;
  margin-bottom: 3px !important;
  font-size: 18px !important;
  border-radius: 10px !important;
  padding: 5px !important;
  border: 1px solid #d1d5db !important;
}
.updateName{
  width: auto !important;
  text-align: center !important;
  border: 0.5px solid #f2dedb !important;
  border-radius: 4px !important;
  margin-bottom: 3px !important;
  margin-left: 5px !important;
  padding: 5px !important;
  font-size: 18px !important;
  background-color: #f2dedb !important;
}
.updateName:hover{
  cursor: pointer;
  background-color: #FFE5DD
}

.set-background-main {
  background-color: #f2dedb !important;
}

.profile-box {
  width: 100%;
  flex-direction: row;
  justify-content: center;
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
}

.background-4 {
  align-items: flex-start;
  background-color: var(--hint-of-red);
  display: flex;
  flex-direction: column;
  left: 0;
  min-height: 1421px;
  padding: 236px 410px;
  position: absolute;
  top: 0;
  width: 1920px;
}

.group-127 {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  min-height: 315px;
  position: relative;
  width: 275px;
}

.super-admin-6 {
  height: 35px !important;
  letter-spacing: 1px !important;
  text-align: center !important;
  /* width: 107px; */
  margin-top: 16px !important;
  margin-left: 2px !important;
  line-height: 14px !important;
  background-color: #f2dedb!important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.super-admin-6-v2 {
  height: 35px !important;  
  letter-spacing: 1px !important;
  text-align: center !important;
  /* width: 107px; */
  margin-top: 16px !important;
  margin-left: 2px !important;
  line-height: 14px !important;
  background-color: #f2dedb!important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.super-admin-6-v2:hover{
  cursor: pointer !important;
}

.complemento-admin{
    font-family: var(--font-family-manrope) !important;
    font-size: var(--font-size-l) !important;
    font-style: normal !important;
    font-weight: 400 !important;
    width: 100% !important;
}

.group-23 {
  justify-content: left !important;
  display: flex !important;
  height: 270px !important;
  width: 509px !important;
  margin-top: 40px !important;
}

.group-123 {
  align-items: center;
  display: flex;
  flex: 1;
  min-width: 511px;
}

.overlap-group1-15 {
  height: 0px !important;
  position: relative;
  width: 445px;
}

.big-title {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 0;
  min-height: 0px !important;
  position: absolute;
  top: 0;
  width: 0px !important;
}

.dashboard-5 {
  height: 54px;
  letter-spacing: 0;
  line-height: 38px;
  margin-top: -2px;
  width: 330px;
}

.level-status-custo {
  letter-spacing: 0;
  line-height: 26px;
  margin-top: 0px;
  min-height: 78px;
  white-space: nowrap;
}

.phone {
  letter-spacing: 0 !important;
  line-height: 26px !important;
  margin-left: 0px !important;
  margin-top: 26px !important;
  min-height: 0px !important;
  white-space: nowrap !important;
}

.email {
  color: #7a86a1;
  font-family: var(--font-family-manrope);
  font-size: var(--font-size-xxxl);
  letter-spacing: 0;
  line-height: 26px;
  margin-left: 34px;
  margin-top: 55px;
  min-height: 26px;
  white-space: nowrap;
}
.newAvatar{
  height: 100%;
  border-radius: 14px;
  justify-content: flex-end;
  align-items: flex-end;
  display: flex;
}
.inputImage{
  margin-bottom: 3vh!important;
  margin-top: 3vh !important;
  width: 100% !important;
  display: flex !important;
  font-size: 20px !important;
  font-family: unset !important;
  border: #808080 !important;
  border-width: 1px !important;
  border-style: solid !important;
  border-radius: 5px !important;
  padding: 1vh !important;
}

input[type="file"]::file-selector-button {
  background-color: #f2dedb !important;
  color: #000 !important;
  padding: 5px !important;
  border-radius: 5px;
}

.botonSaveModal{
  background-color: #f2dedb;
  color: #000;
}
.botonSaveModal:hover{
  background-color: #f2dedb;
  color: #000;
}
.column1{
  display: flex !important;
  justify-content: left !important;
  width: 400px !important;
}
.column2, .column4{
  width: 70% !important;
}
.column3{
  width: 80% !important;
  display: flex !important;
}
.overlap-group-38-1 {
  width: max-content !important;
  margin-top: 100px!important;
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
}
.labelInput{
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  width: 100% !important;
}

.textDasboard2{
  color: #202020 !important;
  font-family: "Manrope-Bold" !important;
  font-size: 20px !important;
  font-weight: 700 !important;
  font-style: normal !important;
}

.textBeforeEmail{
  color: rgba(165, 165, 165, 1) !important;
  font-family: "Manrope" !important;
  font-size: 18px !important;
  font-weight: 400 !important;
  font-style: normal !important;
}

.dashboard-6 {
  height: 33px;
  left: 0;
  letter-spacing: 0;
  line-height: 38px;
  position: absolute;
  top: 0;
  white-space: nowrap;
  width: 330px;
}

.plunderdesigncomweb_alias {
  left: 166px;
  letter-spacing: 0;
  line-height: 26px;
  position: absolute;
  top: 8px;
  white-space: nowrap;
}

.icon-call {
  height: 22px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 221px;
  width: 22px;
}

.email-2-1 {
  height: 14px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 305px;
  width: 20px;
}

.super-admin-container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  margin-left: 0px !important;
  margin-top: 145.0px;
  min-height: 98px;
  width: 59px;
}

.super-admin-7 {
  cursor: pointer;
  height: 28px;
  letter-spacing: 0;
  line-height: 14px;
  width: 59px;
}

.super-admin-8 {
  cursor: pointer;
  height: 28px;
  letter-spacing: 0;
  line-height: 14px;
  margin-top: 42px;
  width: 59px;
}

.group-113-2 {
  align-items: center;
  background-color: var(--white);
  display: flex;
  height: 120px;
  justify-content: flex-end;
  left: 0;
  min-width: 1920px;
  padding: 0 50px;
  position: absolute;
  top: 0;
}

.profile-6 {
  align-items: center;
  cursor: pointer;
  display: flex;
  margin-bottom: 1.0px;
  margin-left: 68px;
  min-width: 223px;
  position: relative;
}

.overlap-group1-16 {
  align-items: flex-start;
  background-color: var(--silver-sand);
  border-radius: 14px;
  display: flex;
  height: 57px;
  justify-content: flex-end;
  min-width: 57px;
  padding: 15.0px 17.0px;
}

.icon-5 {
  height: 26px;
  object-fit: cover;
  width: 22px;
}

.overlap-group4-6 {
  height: 1455px;
  left: 0;
  position: absolute;
  top: 0;
  width: 345px;
}

.footer-2 {
  background-color: var(--white);
  box-shadow: 18px 4px 70px #00000005;
  height: 1421px;
  left: 0;
  position: absolute;
  top: 0;
  width: 345px;
}

.menus-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 30px;
  min-height: 220px;
  position: absolute;
  top: 120px;
  width: 285px;
}

.x01-5 {
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  height: 50px;
  min-width: 285px;
  padding: 9px 22.3px;
  position: relative;
}

.dashboard-7 {
  align-self: flex-end;
  height: 31px;
  letter-spacing: 0;
  line-height: 18px;
  margin-left: 28px;
  width: 98px;
}

.x02-2 {
  align-items: center;
  background-color: var(--white);
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  height: 50px;
  margin-top: 14px;
  min-width: 285px;
  padding: 9px 25.2px;
}

.proposal-2 {
  background-position: 50% 50%;
  background-size: cover;
  height: 24px;
  width: 18px;
}

.projects-12 {
  align-self: flex-end;
  height: 29px;
  letter-spacing: 0;
  line-height: 18px;
  margin-left: 31px;
  width: 145px;
}

.group-83-2 {
  align-items: flex-end;
  background-color: var(--bizarre);
  border-radius: 10px;
  display: flex;
  height: 50px;
  margin-top: 6px;
  min-width: 285px;
  padding: 11px 26px;
  position: relative;
}

.group-84-2 {
  align-items: flex-end;
  background-color: var(--white);
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  height: 50px;
  min-width: 285px;
  padding: 11px 26px;
  position: relative;
}

.overlap-group1-17 {
  align-items: flex-end;
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 10px;
  display: flex;
  height: 600px;
  left: 30px;
  min-width: 285px;
  padding: 17.2px 14px;
  position: absolute;
  top: 382px;
}

.button-withdraw-all-earning-11 {
  align-items: flex-start;
  background-color: var(--bizarre);
  border-radius: 10px;
  box-shadow: 2.78px 15.76px 40px #695f970d;
  display: flex;
  height: 40px;
  justify-content: flex-end;
  min-width: 108px;
  padding: 0 9.7px;
}

.learn-more-4 {
  letter-spacing: 0.08px;
  line-height: 49px;
  margin-top: -3.5px;
  min-height: 20px;
  min-width: 86px;
  text-align: center;
  white-space: nowrap;
}

.overlap-group2-9 {
  background-position: 50% 50%;
  background-size: cover;
  height: 224px;
  left: 18px;
  position: absolute;
  top: 1001px;
  width: 309px;
}

.overlap-group1-18 {
  height: 200px;
  left: 27px;
  position: absolute;
  top: 8px;
  width: 270px;
}

.your-image-here-4 {
  height: 191px;
  left: 79px;
  object-fit: cover;
  position: absolute;
  top: 7px;
  width: 191px;
}

.sleepangle-4 {
  height: 200px;
  left: 142px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 128px;
}

.group-25-4 {
  align-items: flex-start;
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: flex-end;
  left: 0;
  min-width: 114px;
  position: absolute;
  top: 147px;
}

.overlap-group-39 {
  align-items: flex-start;
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  height: 160px;
  margin-top: -44.24px;
  min-width: 234px;
  padding: 40.7px 86.0px;
}

.explore-2 {
  letter-spacing: 0.08px;
  line-height: 49px;
  min-height: 20px;
  min-width: 58px;
  text-align: center;
  white-space: nowrap;
}

.ongoing-4 {
  height: 46px;
  left: 0;
  letter-spacing: 0;
  line-height: 12px;
  position: absolute;
  top: 61px;
  width: 114px;
}

.just-in-2 {
  left: 27px;
  letter-spacing: 0;
  line-height: 26px;
  position: absolute;
  top: 34px;
  white-space: nowrap;
}

.overlap-group3-5 {
  background-position: 50% 50%;
  background-size: cover;
  height: 224px;
  left: 18px;
  position: absolute;
  top: 1231px;
  width: 309px;
}

.your-image-here-5 {
  height: 191px;
  left: 106px;
  object-fit: cover;
  position: absolute;
  top: 15px;
  width: 191px;
}

.sleepangle-5 {
  height: 200px;
  left: 169px;
  object-fit: cover;
  position: absolute;
  top: 8px;
  width: 128px;
}

.group-25-5 {
  align-items: flex-start;
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: flex-end;
  left: 24px;
  min-width: 114px;
  position: absolute;
  top: 158px;
}

.overlap-group-40 {
  align-items: flex-start;
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  height: 160px;
  margin-top: -44.24px;
  min-width: 234px;
  padding: 40.7px 80.0px;
}

.subscribe-2 {
  letter-spacing: 0.08px;
  line-height: 49px;
  min-height: 20px;
  min-width: 70px;
  text-align: center;
  white-space: nowrap;
}

.posse-box-2 {
  left: 24px;
  letter-spacing: 0;
  line-height: 26px;
  position: absolute;
  top: 50px;
  white-space: nowrap;
}

.ongoing-5 {
  height: 46px;
  left: 24px;
  letter-spacing: 0;
  line-height: 12px;
  position: absolute;
  top: 84px;
  width: 133px;
}

.my-account {
  left: 408px;
  letter-spacing: 0;
  line-height: 44px;
  position: absolute;
  top: 156px;
  white-space: nowrap;
}

.editData{
  font-size: 18px !important;
  border-radius: 5px !important;
  border: 0px !important;
  width: 100px !important;
  margin-left: 10px !important;
  background-color: #f2dedb!important;
  border: none !important;
  height: 30px !important;
}
.editData:hover{
  cursor: pointer;
}
.inputData{
  font-size: 18px !important;
  border-radius: 5px !important;
  width: 300px !important;
  padding: 1px !important;
  margin-left: 5px !important;
}
.input-addon {
  /* border: 1px solid #d1d5db; */
  border-radius: 0.25rem;
  display: flex;
}

.input-addon__input {
  border: 1px solid #d1d5db;
  flex: 1;
}

.input-addon__addon {
  align-items: center;
  display: flex;
  justify-content: center;
}

.input-addon__addon--appended {
  border-left: 1px solid #d1d5db;
}
.input-addon__addon--appended {
  border-left: 1px solid #d1d5db;
}
@media (max-width: 796px) {
  .overlap-group-38-1 {
    display: flex !important;
    flex-direction: column !important;
  }
  .container-my-account{
    align-items: center !important;
    margin: 10px 10px !important;
  }
  .labelInput{
    margin-top: 2% !important;
    margin-bottom: 2% !important;
  }
  .column4{
    width: 100% !important;
  }
  .profile-box{
    overflow-x: overlay !important;
  }
  .phone{
    margin-left: 0px !important;
  }
}
@media only screen and (min-width: 600px) and (max-width: 795px) {
  .overlap-group1-15{
    width: 345px !important;
  }
  .profile-box{
    width: 100% !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    display: flex!important;
    overflow-x: overlay !important;
    overflow-y: hidden !important;
  }
  .column1{
    justify-content: center!important;
  }
  .phone{
    margin-left: 0px !important;
  }
}
@media only screen and (min-width: 0px) and (max-width: 599px) {
  .overlap-group1-15{
    width: 145px !important;
  }
  .overlap-group-38-1 {
    width: 400px !important;
  }
  .profile-box{
    overflow-x: overlay !important;
    overflow-y: hidden !important;
  }
  .column1{
    justify-content: center!important;
  }
  .phone{
    margin-left: 0px !important;
  }
}