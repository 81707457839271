.date-9-1 {
  align-items: flex-end;
  display: flex;
  height: 13px;
  left: 0;
  position: absolute;
  top: 19px;
  width: 96px;
}

.calendar-3 {
  background-image: url(/static/img/vector-64@1x.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 9.95px;
  margin-bottom: 0.6px;
  width: 11.02px;
}

.date-11 {
  flex: 1;
  letter-spacing: 0;
  line-height: 10px;
  margin-bottom: -2px;
  margin-left: 5.6px;
  margin-right: -2.0px;
}

.date-9-1.date-12,
.date-9-1.date-12-1,
.date-9-1.date-12-2 {
  height: 14px;
}

.date-9-1.date-12-1 .calendar-3,
.date-9-1.date-12-2 .calendar-3,
.date-9-1.date-12 .calendar-3 {
  margin-bottom: 1.5px;
}

