.group-73,
.group-73-1 {
  height: 116px;
  left: 0;
  position: absolute;
  top: 0;
  width: 670px;
}

.new-rectangle-32::-webkit-progress-bar{
  background-color: white;
}

.new-rectangle-32::-webkit-progress-value{
  background-color: #D7B696;
}

.new-barra-2::-webkit-progress-bar{
  background-color: white;
}

.new-barra-2::-webkit-progress-value{
  background-color: #D7B696;
}

.barra-3 {
  appearance: none;
  -webkit-appearance: none;
}

.barra-3::-webkit-progress-bar {
  background-color: white;
}

.barra-3::-webkit-progress-value {
  background-color: #D8B696;
}
.overlap-group2-3,
.overlap-group2-4 {
  height: 92px;
  left: 0;
  position: absolute;
  top: 29px;
  width: 670px;
}

.new-overlap-group1-6,
.new-overlap-group1-7 {
  height: 70px;
  left: 0;
  position: absolute;
  top: 8px;
  width: 667px;
}

.new-rectangle-31-3,
.new-rectangle-31-4 {
  background-color: var(--new--sonic-silver);
  border-radius: 0px 5px 5px 0px;
  height: 41px;
  left: 34px;
  position: absolute;
  top: 14px;
  width: 633px;
}

.new-rectangle-32-3,
.new-rectangle-32-4 {
  background-color: var(--new--cameo);
  height: 41px;
  left: 34px;
  position: absolute;
  top: 14px;
  width: 423px;
}

.new-path-64-2,
.new-path-64-3 {
  height: 70px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 70px;
}

.new-path-container-1,
.new-path-container-2 {
  height: 37px;
  left: 19px;
  position: absolute;
  top: 16px;
  width: 30px;
}

.new-path-51-1,
.new-path-51-2 {
  height: 16px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 22px;
  width: 15px;
}

.new-path-52-1,
.new-path-52-2 {
  height: 16px;
  left: 16px;
  object-fit: cover;
  position: absolute;
  top: 22px;
  width: 15px;
}

.new-path-53-1,
.new-path-53-2 {
  height: 13px;
  left: 9px;
  object-fit: cover;
  position: absolute;
  top: 7px;
  width: 13px;
}

.new-path-54-1,
.new-path-54-2 {
  height: 26px;
  left: 2px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 26px;
}

.new-price-1,
.new-price-3 {
  left: 304px;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  top: 30px;
  white-space: nowrap;
  color: #747474;
}

.new-price-2,
.new-price-4 {
  left: 610px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  top: 0;
  white-space: nowrap;
  color: black;
}

.x25-more-to-10-bonus,
.x25-more-to-10-bonus-1 {
  left: 331px;
  letter-spacing: 0;
  line-height: 14px;
  position: absolute;
  text-align: right;
  top: 70px;
  white-space: nowrap;
}

.new-activity-logs-2,
.new-activity-logs-3 {
  height: 31px;
  left: 0;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 0;
  width: 320px;
}

