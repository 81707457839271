.group-85 {
  align-items: flex-start;
  display: flex;
  margin-bottom: 0;
  margin-top: 2.9px;
  min-width: 17.24px;
  padding: 0 0.0px;
}

.path-container {
  height: 24px;
  margin-top: 0;
  position: relative;
  width: 17px;
}

.path-69 {
  height: 5px;
  left: 4px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 9px;
}

.path-70 {
  height: 22px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 3px;
  width: 17px;
}

.group-85.group-85-3 {
  align-items: flex-end;
  padding: 0.0px 0.0px;
}

.group-85.group-85-3 .path-container,
.group-85.group-94-3 .path-container {
  margin-top: unset;
}

.group-85.group-94,
.group-85.group-94-1,
.group-85.group-94-2 {
  justify-content: center;
  margin-bottom: unset;
  margin-top: unset;
  min-width: 17px;
  padding: unset;
}

.group-85.group-94-3 {
  align-items: flex-end;
  justify-content: center;
  margin-bottom: unset;
  margin-top: unset;
  min-width: 17px;
  padding: 0.0px 0;
}

.group-85.group-85-4 {
  justify-content: center;
  margin-bottom: unset;
  margin-top: 0.13px;
  min-width: 17px;
  padding: unset;
}

