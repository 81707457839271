.group-103 {
  align-items: center;
  display: flex;
  margin-left: 0;
  margin-top: 27px;
  min-width: 286px;
}

.group-100 {
  align-items: flex-start;
  background-color: var(--new--silver-sand);
  border-radius: 16px;
  display: flex;
  height: 53px;
  justify-content: flex-end;
  margin-top: 0;
  min-width: 54px;
  padding: 12.4px 16.3px;
}

.new-icon-user-1 {
  height: 26px;
  object-fit: cover;
  width: 21px;
}

.new-overlap-group1-3 {
  height: 67px;
  margin-left: 15px;
  position: relative;
  width: 219px;
}

.due-date-res {
  height: 34px;
  display: flex;
  width: 111px;
}

.x1000-1100-6 {
  flex: 1;
  letter-spacing: 0;
  line-height: 12px;
  margin-bottom: -2px;
  margin-right: -2px;
  text-align: right;
  width: 111px;
}

.group-102 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 0;
  min-height: 67px;
  position: absolute;
  top: 0;
  width: 219px;
}

.new-overlap-group-12 {
  height: 44px;
  position: relative;
  width: 219px;
}

.meeting-with-client-3 {
  height: 35px;
  left: 0;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  top: 0;
  width: 219px;
}

.x1000-1100-7 {
  height: 24px;
  letter-spacing: 0;
  line-height: 10px;
  margin-left: 2px;
  margin-top: 1px;
  width: 217px;
}
.style-v2 {
  color: #0289d0;
  font-family: var(--new--font-family-manrope);
  font-size: var(--new--font-size-xxs);
  font-style: normal;
  font-weight: 800;
}

.group-103.group-104,
.group-103.group-104-1,
.group-103.group-104-2 {
  margin-top: 6px;
}

.group-103.group-104 .group-100,
.group-103.group-104-1 .group-100,
.group-103.group-104-2 .group-100 {
  padding: 12.3px 16.3px;
}

.group-103.group-104 .new-overlap-group1-3,
.group-103.group-104-1 .new-overlap-group1-3,
.group-103.group-104-2 .new-overlap-group1-3 {
  align-self: flex-end;
  margin-bottom: 0;
}

