.group-103 {
  align-items: center;
  display: flex;
  margin-left: 0;
  margin-top: 27px;
  min-width: 341px;
}

.group-100 {
  align-items: flex-start;
  background-color: var(--silver-sand);
  border-radius: 16px;
  display: flex;
  height: 53px;
  justify-content: flex-end;
  margin-top: 0;
  min-width: 54px;
  padding: 12.4px 16.3px;
}

.icon-user-4 {
  height: 26px;
  object-fit: cover;
  width: 21px;
}

.overlap-group1-6 {
  height: 67px;
  margin-left: 15px;
  position: relative;
  width: 274px;
}

.due-date {
  display: flex;
  height: 34px;
  left: 163px;
  position: absolute;
  top: 16px;
  width: 111px;
}

.x1000-1100-4 {
  flex: 1;
  letter-spacing: 0;
  line-height: 12px;
  margin-bottom: -2px;
  margin-right: -2px;
  text-align: right;
  width: 111px;
}

.group-102 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 0;
  min-height: 67px;
  position: absolute;
  top: 0;
  width: 219px;
}

.overlap-group-26 {
  height: 44px;
  position: relative;
  width: 219px;
}

.meeting-with-client-2 {
  height: 35px;
  left: 0;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  top: 0;
  width: 219px;
}

.x1000-1100-5 {
  height: 24px;
  letter-spacing: 0;
  line-height: 10px;
  margin-left: 2px;
  margin-top: 1px;
  width: 217px;
}

.group-103.group-104,
.group-103.group-104-1 {
  margin-top: 6px;
}

.group-103.group-104 .group-100,
.group-103.group-104-1 .group-100 {
  padding: 12.3px 16.3px;
}

.group-103.group-104 .overlap-group1-6,
.group-103.group-104-1 .overlap-group1-6 {
  align-self: flex-end;
  margin-bottom: 0;
}

