.group-98 {
  align-items: flex-start;
  align-self: center;
  background-color: var(--new--silver-sand);
  border-radius: 16px;
  display: flex;
  height: 55px;
  justify-content: flex-end;
  margin-bottom: 3.0px;
  min-width: 54px;
  padding: 14.6px 16.3px;
}

.new-icon-user-4 {
  height: 26px;
  object-fit: cover;
  width: 21px;
}

.group-98.group-98-1,
.group-98.group-98-3,
.group-98.group-98-5 {
  margin-bottom: 2.0px;
  padding: 14.5px 16.3px;
}

