.desktop-edit-cell-number {
  align-items: flex-start;
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  height: 1421px;
  padding: 151px 166px;
  width: 1920px;
}

.group-21 {
  align-items: flex-start;
  background-color: var(--cultured-pearl);
  border-radius: 10px;
  box-shadow: 0px 3px 6px #00000029;
  display: flex;
  flex-direction: column;
  margin-left: 1152px;
  min-height: 200px;
  padding: 22.0px 21.4px;
  width: 436px;
}

.active-2 {
  display: flex;
  height: 86px;
  margin-top: 11px;
  width: 359px;
}

.input-2 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 100%;
  width: 358.68px;
}

.input-box-2 {
  height: 24px;
  letter-spacing: 1.00px;
  line-height: 16px;
  margin-left: 0.71px;
  width: 151px;
}

.overlap-group-2 {
  align-items: flex-start;
  border-radius: 14px;
  display: flex;
  height: 60px;
  margin-top: 2px;
  min-width: 359px;
  padding: 10px 21.0px;
}

.input-here-4 {
  height: 39px;
  letter-spacing: 0;
  line-height: 20px;
  width: 156px;
}

.flex-row-2 {
  align-items: center;
  display: flex;
  margin-left: 0;
  margin-top: 20px;
  min-width: 227px;
}

.button-withdraw-all-earning-4 {
  align-items: flex-start;
  background-color: var(--bizarre);
  border-radius: 10px;
  box-shadow: 2.78px 15.76px 40px #695f970d;
  display: flex;
  height: 40px;
  justify-content: flex-end;
  min-width: 143px;
  padding: 0 26.4px;
}

.save-changes-1 {
  letter-spacing: 0;
  line-height: 49px;
  margin-top: -4.7px;
  min-height: 20px;
  min-width: 88px;
  text-align: center;
  white-space: nowrap;
}

.super-admin-1 {
  height: 28px;
  letter-spacing: 0;
  line-height: 14px;
  margin-left: 25px;
  margin-top: 3.59px;
  width: 59px;
}

