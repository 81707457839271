.group-2,
.group-2-1,
.group-2-2,
.group-2-3,
.group-2-4 {
  align-items: center;
  display: flex;
  height: 48px;
  margin-left: 338px;
  min-width: 133px;
}

.united-states-1,
.united-states-1-1,
.united-states-1-2,
.united-states-1-3,
.united-states-1-4 {
  align-items: flex-end;
  background-image: url(/static/img/vector-2@1x.png);
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  margin-top: 2.0px;
  min-width: 28px;
}

.overlap-group-7,
.overlap-group-8,
.overlap-group-9,
.overlap-group-10,
.overlap-group-11 {
  height: 28px;
  margin-bottom: 0;
  position: relative;
  width: 28px;
}

.group,
.group-1,
.group-3,
.group-4,
.group-5 {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  left: 0;
  min-height: 25px;
  position: absolute;
  top: 3px;
  width: 28px;
}

.vector-18,
.vector-23,
.vector-28,
.vector-33,
.vector-38 {
  height: 4px;
  margin-right: 2.05px;
  object-fit: cover;
  width: 13px;
}

.vector-19,
.vector-24,
.vector-29,
.vector-34,
.vector-39 {
  height: 4px;
  margin-right: 0;
  margin-top: 4px;
  object-fit: cover;
  width: 15px;
}

.vector-20,
.vector-25,
.vector-30,
.vector-35,
.vector-40 {
  height: 4px;
  margin-right: 0.48px;
  margin-top: 4px;
  object-fit: cover;
  width: 27px;
}

.vector-21,
.vector-26,
.vector-31,
.vector-36,
.vector-41 {
  align-self: flex-start;
  height: 3px;
  margin-left: 4.8px;
  margin-top: 4px;
  object-fit: cover;
  width: 17px;
}

.vector-22,
.vector-27,
.vector-32,
.vector-37,
.vector-42 {
  height: 14px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 14px;
}

.eng-us,
.eng-us-1,
.eng-us-2,
.eng-us-3,
.eng-us-4 {
  align-self: flex-end;
  height: 50px;
  letter-spacing: 0;
  line-height: 16px;
  margin-bottom: -2px;
  margin-left: 14px;
  width: 65px;
}

.arrow-1,
.arrow-2,
.arrow-3,
.arrow-4,
.arrow-5 {
  height: 8px;
  margin-left: 11px;
  object-fit: cover;
  width: 15px;
}

