.group-68-2 {
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: 67px;
  padding: 0 0.0px;
  width: 45px;
}

.icon-label-3 {
  height: 43px;
  margin-right: 0;
  object-fit: cover;
  width: 45px;
}

.redeem-2 {
  letter-spacing: 0;
  line-height: 7px;
  margin-left: 1.21px;
  margin-top: 15px;
  min-height: 12px;
  min-width: 28px;
  text-align: center;
  text-decoration: underline;
  white-space: nowrap;
}

.group-68-2.group-67-2 {
  margin-left: 8px;
}

.group-68-2.group-66-2 {
  margin-left: 7px;
}

