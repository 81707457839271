.group-68 {
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: 127px;
  width: 85px;
}

.icon-label {
  height: 82px;
  margin-right: 0;
  object-fit: cover;
  width: 85px;
}

.redeem {
  letter-spacing: 0;
  line-height: 14px;
  margin-left: 1.27px;
  margin-top: 29px;
  min-height: 21px;
  min-width: 52px;
  text-align: center;
  text-decoration: underline;
  white-space: nowrap;
}

.pointer-redeem{
  cursor: pointer;
}

.group-68.group-67,
.group-68.group-67-1 {
  margin-left: 15px;
}

.group-68.group-67 .icon-label,
.group-68.group-67-1 .icon-label {
  margin-left: 0;
  margin-right: unset;
}

.group-68.group-66,
.group-68.group-66-1 {
  margin-left: 14px;
}

.App {
  text-align: center;
}

.overlay-base {
  padding: 1rem;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0);
  opacity: 0;
  transition-property: background-color, opacity;
  transition-duration: 500ms;
  transition-timing-function: ease-in-out;
  outline: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay-after {
  background-color: rgba(0, 0, 0, 0.8);
  opacity: 1;
  z-index: 99999;

}

.overlay-before {
  background-color: rgba(0, 0, 0, 0);
  opacity: 0;
}

.content-base {
  max-height: calc(100vh - 210px);
  overflow-y: auto;
  position: relative;
  border-radius: 5px;
  /*
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
  */
  margin: 0 auto;
  border: 0;
  outline: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 0%;
  width: 0%;
  background-color: transparent;
  transition-property: background-color, width, height;
  transition-duration: 500ms;
  transition-timing-function: ease-in-out;
  flex-direction: column;
}

.content-after-max {
  width: 70%;
  height: 60% !important;
  background-color: #fff;
}

.content-after {
  width: 70%;
  height: 80%;
  background-color: #fff;
}

.content-before {
  width: 0%;
  height: 0%;
  background-color: transparent;
}

.close-modal {
  position: absolute;
  top: 1em;
  right: 1em;
  z-index: 100;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  width: 25px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  border-radius: 50%;
  transition: all .5s;
  display: flex;
  justify-content: center;
  align-items: center;border: 0;
}

.text-close-modal {
  font-family: 'HCo Gotham',-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Ubuntu,sans-serif;
  color: #c9c9c9;
}

.text-close-modal:hover {
  color: #b0b0b0;
}

.card-product-modal {
  cursor: pointer;
  max-width: 32%;
  background-color: white;
  border-radius: 5px;
  border: 1px solid rgba(168, 168, 168, 0.707);
}
/* @media (max-width: 796px) {
  .card-product-modal{
    max-width: 50% !important;
    width: 49% !important;
  }
} */
.card-product-modal:hover {
  box-shadow: 0px 0px 30px 0px rgb(0 0 0 / 8%);
}

.card-product-modal-body {
  padding: 12px;
  font-size: large;
}

.modal-container {
  margin-top: 30px;
  width: 80%;
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 20px;
}

.apply-coupon {
  margin-top: 5px;
  background-color: #1F2328 !important;
}

.title-modal {
  font-family: 'HCo Gotham', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, sans-serif;
  display: block;
  color: black;
  font-weight: bold;
  justify-content: center;
  font-size: 24px;
  margin-top: 10px;
}

.title-modal-v2 {
  /* margin-top: 23% !important;
   */
  margin-top: 15% !important;

}

.title-modal-v2-2 {
  margin-top: 5% !important;
}

.modal-container-v2 {
  margin-left: 18% !important;
  margin-top: 30px;
  width: 80%;
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 20px;
}

.image-header {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.content-product-details {
  width: 50%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 35px;
  display: flex;
  z-index: 9999999;
  margin-left: auto;
  margin-top: 10%;
  margin-right: auto;
}

.content-product-details-image {
  width: 65%;
  z-index: 999999;
}

.content-product-details-content {
  width: 40%;
  z-index: 999999;
  font-size: 24px;
}

.content-product-details-content-title {
  font-weight: 600;
}

.image-detail {
  width: 100%;
}

.button-apply-coupon {
  cursor: pointer;
  width: 75%;
  border: 0;
  background-color: #F2DEDB;
  color: #000;
  font-family: 'HCo Gotham', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, sans-serif;
  text-align: center;
  padding: 10px;
  font-size: initial;
}

.button-apply-coupon:hover {
  box-shadow: 0px 0px 30px 0px rgb(0 0 0 / 8%);
}

.product-details-show {
  font-size: 18px;
}

.product-details-literal {
  font-family: 'HCo Gotham', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, sans-serif;
  font-weight: bold;
}

.button-back-coupon {
  cursor: pointer;
  width: 100%;
  border: 0;
  background-color: rgb(30, 29, 29);
  color: rgb(255, 255, 255);
  font-family: 'HCo Gotham', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, sans-serif;
  text-align: center;
  padding: 10px;
  font-size: initial;
}
.button-back-coupon-v2 {
  cursor: pointer;
    width: 100%;
    color: #000;
    text-align: center;
    font-family: HCo Gotham,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Ubuntu,sans-serif;
    font-size: initial;
    background-color: #fff;
    border-width: 1px;
    padding: 10px;
}

.button-back-coupon:hover {
  box-shadow: 0px 0px 30px 0px rgb(0 0 0 / 8%);
}

.serchProducts {
  width: 100%;
  margin-top: 20px;
}

.input-container {
  width: 50%;
}

.product-details-show-letters {
  font-size: 10px;
  margin-top: 7px;
}