.group-85 {
  align-items: flex-start;
  display: flex;
  height: 24.14px;
  margin-top: 1.0px;
  min-width: 17.24px;
  padding: 0.0px 0.0px;
}

.new-path-container-3 {
  height: 24px;
  position: relative;
  width: 17px;
}

.path-69-1 {
  height: 5px;
  left: 4px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 9px;
}

.path-70-1 {
  height: 22px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 3px;
  width: 17px;
}

.group-85.group-85-1,
.group-85.group-85-2,
.group-85.group-85-3 {
  margin-top: 0;
  padding: 0 0.0px;
}

.group-85.group-85-1 .new-path-container-3,
.group-85.group-85-2 .new-path-container-3,
.group-85.group-85-3 .new-path-container-3,
.group-85.group-85-4 .new-path-container-3,
.group-85.group-85-6 .new-path-container-3,
.group-85.group-85-7 .new-path-container-3,
.group-85.group-85-8 .new-path-container-3,
.group-85.group-85-9 .new-path-container-3,
.group-85.group-85-11 .new-path-container-3 {
  margin-top: 0;
}

.group-85.group-85-4 {
  padding: 0 0.0px;
}

.group-85.group-85-5 {
  height: unset;
  justify-content: center;
  margin-top: unset;
  min-width: 17px;
  padding: 0.0px 0;
}

.group-85.group-85-6,
.group-85.group-85-7,
.group-85.group-85-8,
.group-85.group-85-9 {
  height: unset;
  justify-content: center;
  margin-top: unset;
  min-width: 17px;
  padding: unset;
}

.group-85.group-85-10 {
  height: unset;
  justify-content: center;
  margin-top: 0.13px;
  min-width: 17px;
  padding: 0.0px 0;
}

.group-85.group-85-11 {
  height: unset;
  justify-content: center;
  margin-top: 0.13px;
  min-width: 17px;
  padding: unset;
}

