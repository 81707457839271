.recent-activity {
  background-color: var(--new--sonic-silver);
  border-radius: 10px;
  box-shadow: 0px 4px 8px #0000000d;
  display: flex;
  height: 442px;
  left: 1115px;
  position: absolute;
  top: 740px;
  width: 335px;
}

.show-scroll-activity {
  overflow: hidden;
  overflow-y: scroll;
}

.font-size-noactivity {
  font-size: 14px !important;
}

.group-250 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: 21.0px;
  margin-left: 23px;
  margin-right: 23.0px;
  margin-top: 20px;
  min-height: calc(100.00% - 41px);
  position: relative;
  width: 289px;
}

.schedule {
  display: flex;
  height: 73px;
  margin-left: 6px;
  width: 220px;
}

.new-title-1 {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  width: 222px;
}

.schedule-1 {
  flex: 1;
  height: 47px;
  letter-spacing: 0;
  line-height: 24px;
  margin-right: 27px;
  max-height: 47px;
}

.thursday-january-10th-2022 {
  flex: 1;
  height: 24px;
  letter-spacing: 0;
  line-height: 16px;
  margin-bottom: -2px;
  margin-right: -2px;
  max-height: 24px;
}

.group-250-item {
  align-items: flex-start;
  display: flex;
  margin-top: 11px;
  min-width: 289px;
  position: relative;
}

.overlap-group2 {
  height: 70px;
  margin-left: 15px;
  margin-top: 0;
  position: relative;
  width: 222px;
}

.group-97 {
  height: 70px;
  left: 0;
  position: absolute;
  top: 0;
  width: 222px;
}

.new-overlap-group-6 {
  height: 45px;
  left: 0;
  position: absolute;
  top: 0;
  width: 221px;
}

.meeting-with-client {
  height: 35px;
  left: 0;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  top: 0;
  width: 221px;
}

.date {
  align-items: flex-end;
  display: flex;
  height: 19px;
  left: 1px;
  position: absolute;
  top: 26px;
  width: 130px;
}

.calendar {
  background-image: url(/static/img/new-vector-13@1x.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 13.56px;
  margin-bottom: 2.5px;
  width: 15.01px;
}

.date-1 {
  flex: 1;
  letter-spacing: 0;
  line-height: 10px;
  margin-bottom: -2px;
  margin-left: 8.0px;
  margin-right: -2px;
}

.x1000-1100 {
  height: 27px;
  left: 2px;
  letter-spacing: 0;
  line-height: 10px;
  position: absolute;
  top: 45px;
  width: 220px;
}

.new-overlap-group4-1 {
  height: 69px;
  margin-left: 15px;
  margin-top: 0;
  position: relative;
  width: 222px;
}

.group-97-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 0;
  min-height: 69px;
  position: absolute;
  top: 0;
  width: 222px;
}

.new-overlap-group-7 {
  height: 44px;
  position: relative;
  width: 221px;
}

.x1000-1100-1 {
  height: 27px;
  letter-spacing: 0;
  line-height: 10px;
  margin-left: 2px;
  width: 220px;
}

.recent-activity.recent-activity-1 {
  left: unset;
  margin-top: 20px;
  position: unset;
  top: unset;
}

.recent-activity.recent-activity-1 .group-250,
.recent-activity.recent-activity-2 .group-250 {
  margin-bottom: 20.0px;
  margin-top: 21px;
}

.recent-activity.recent-activity-1 .calendar,
.recent-activity.recent-activity-2 .calendar {
  width: 15.01px;
}

.recent-activity.recent-activity-2 {
  align-self: flex-start;
  left: unset;
  margin-left: 16px;
  margin-top: 12px;
  position: unset;
  top: unset;
}

