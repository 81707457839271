.pagination {
  align-items: flex-start;
  display: flex;
  height: 58px;
  left: 37px;
  min-width: 340px;
  position: absolute;
  top: 1022px;
}

.btn-prev {
  align-items: flex-start;
  border-radius: 14px;
  display: flex;
  height: 50px;
  min-width: 50px;
  padding: 18px;
}

.ic_chevron {
  align-items: flex-start;
  display: flex;
  height: 12px;
  min-width: 13px;
  transform: rotate(180deg);
}

.vector-container-12 {
  height: 15px;
  margin-left: -1px;
  margin-top: -1.41px;
  position: relative;
  width: 15px;
}

.vector-60 {
  height: 15px;
  left: 7px;
  object-fit: cover;
  position: absolute;
  top: 0;
  transform: rotate(-180deg);
  width: 8px;
}

.vector-61 {
  height: 15px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  transform: rotate(-180deg);
  width: 8px;
}

.number-12 {
  align-items: flex-end;
  display: flex;
  height: 58px;
  margin-left: 10px;
  min-width: 220px;
}

.overlap-group-71 {
  height: 60px;
  margin-bottom: -2px;
  position: relative;
  width: 220px;
}

.background-11 {
  border-radius: 14px;
  height: 51px;
  left: 0;
  position: absolute;
  top: 0;
  width: 220px;
}

.selected-page {
  background-color: var(--bizarre);
  border-radius: 14px;
  box-shadow: 0px 7px 32px #00000024;
  height: 51px;
  left: 0;
  position: absolute;
  top: 0;
  width: 51px;
}

.number-13 {
  height: 49px;
  left: 22px;
  letter-spacing: 0;
  line-height: 18px;
  position: absolute;
  top: 11px;
  width: 9px;
}

.number-14 {
  height: 49px;
  left: 73px;
  letter-spacing: 0;
  line-height: 18px;
  position: absolute;
  top: 11px;
  width: 13px;
}

.number-15 {
  height: 49px;
  left: 128px;
  letter-spacing: 0;
  line-height: 18px;
  position: absolute;
  top: 11px;
  width: 13px;
}

.number-16 {
  height: 49px;
  left: 183px;
  letter-spacing: 0;
  line-height: 18px;
  position: absolute;
  top: 11px;
  width: 14px;
}

.btn-next {
  align-items: flex-start;
  border-radius: 14px;
  display: flex;
  height: 50px;
  margin-left: 10px;
  min-width: 50px;
  padding: 18px 17px;
}

.ic_chevron-1 {
  align-items: flex-start;
  display: flex;
  height: 12px;
  min-width: 13px;
}

.vector-62 {
  height: 15px;
  left: 7px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 8px;
}

.vector-63 {
  height: 15px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 8px;
}

.pagination.pagination-1 {
  align-self: center;
  height: unset;
  left: unset;
  margin-top: 838px;
  position: unset;
  top: unset;
}

