.group-82,
.group-82-1,
.group-82-2,
.group-82-3,
.group-82-4 {
  display: flex;
  height: 27px;
  width: 193px;
}

.icon-user,
.icon-user-1,
.icon-user-2,
.icon-user-3,
.icon-1 {
  margin-bottom: 3.9px;
  margin-top: 2.9px;
  object-fit: cover;
  width: 16.89px;
}

.projects-1,
.projects-2,
.projects-3,
.projects-4,
.projects-5 {
  flex: 1;
  letter-spacing: 0;
  line-height: 18px;
  margin-bottom: -2px;
  margin-left: 31.1px;
  margin-right: -2px;
}

