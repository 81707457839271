.profile-1,
.profile-2,
.profile-3,
.profile-4 {
  align-items: center;
  display: flex;
  height: 55px;
  margin-left: 24px;
  min-width: 142px;
}

.overlap-group-12,
.overlap-group-13,
.overlap-group-14,
.overlap-group-15 {
  align-self: flex-end;
  height: 57px;
  margin-bottom: -2px;
  position: relative;
  width: 88px;
}

.franklin-jr,
.franklin-jr-1,
.franklin-jr-2,
.franklin-jr-3 {
  height: 32px;
  left: 0;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  top: 0;
  width: 77px;
}

.super-admin-2,
.super-admin-3,
.super-admin-4,
.super-admin-5 {
  height: 28px;
  left: 0;
  letter-spacing: 0;
  line-height: 14px;
  position: absolute;
  top: 29px;
  width: 88px;
}

.arrow-6,
.arrow-7,
.arrow-8,
.arrow-9 {
  height: 8px;
  margin-left: 39px;
  margin-top: 1.0px;
  object-fit: cover;
  width: 15px;
}

