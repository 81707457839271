.button-withdraw-all-earning-2 {
  align-items: flex-start;
  background-color: var(--new--bizarre);
  box-shadow: 2.78px 15.76px 40px #695f970d;
  display: flex;
  height: 35px;
  justify-content: flex-end;
  left: 106px;
  min-width: 146px;
  padding: 2.4px 25.0px;
  position: absolute;
  top: 212px;
}

.shop-now {
  letter-spacing: 0.16px;
  line-height: 30px;
  min-height: 24px;
  min-width: 94px;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
}

.button-withdraw-all-earning-2.button-withdraw-all-earning-1 {
  align-items: flex-start;
  background-color: var(--new--bizarre);
  box-shadow: 2.78px 15.76px 40px #695f970d;
  display: flex;
  height: 35px;
  justify-content: flex-end;
  margin-top: 15px;
  min-width: 146px;
  padding: 2.4px 25.0px;
}

