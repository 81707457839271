.new-desktop-home-stylist-dashboard {
  align-items: flex-start;
  display: flex;
  height: 1421px;
  overflow: hidden;
  width: 1920px;
}

.recent-activity-container {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  margin-top: 15px;
  gap: 15px;
  flex-wrap: wrap;
}

.new-overlap-group9-res {
  width: 100% !important;
  display: grid !important;
  grid-template-columns: 1fr 3fr;
  overflow: hidden;
  /* height: 100%; */
}

.container-header-text-button {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.text-refer {
  width: 308px;
  height: 40px;
  text-align: left;
  border: 1px solid #cfcece;
  border-radius: 5px;
  padding: 10px 0px 10px 5px;
  /* overflow-x: scroll; */
  overflow-y: hidden;
}

.container-copy-text {
  display: flex;
  font-family: Arial, Helvetica, sans-serif;
  justify-content: center;
  gap: 4px;
  height: 38px;
  flex-wrap: wrap;
}

.container-header-text-button {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.copy-text-refer {
  background-color: #F2DEDB;
  width: 82px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: 0px;
  font-family: Arial, Helvetica, sans-serif;
  cursor: pointer;
  box-shadow: rgba(56, 56, 56, 0.161) 0px 2px 5px;
  height: 38px;
}

.copy-text-refer:hover{
  box-shadow: rgba(0, 0, 0, 0.35) 0px 4px 7px;

}

.container-main-home {
  width: 100%;
  padding: 15px;
}

.points-progress-bar-res {
  background-color: var(--new--black);
  height: 35px;
  justify-content: center;
  align-items: center;
  display: flex;
  color: #fff;
  font-family: 'Avenir-Heavy';
}

.container-first {
  display: flex;
}

.card-level-up {
  left: 1281px !important;
}

.container-rewards {
  display: flex;
  justify-content: center;
  gap: 20px;
  width: 100%;
  flex-wrap: wrap;
}

.cards-container-rewards {
  width: 100% !important;
  display: flex;
  align-items: center;
  gap: 77px;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 20px;
}

.new-barra-progress-2 {
  width: 610px !important;
  height: 69px !important;
  top: 0px !important;
}

.card-level-up-img {
  max-width: 60px !important;
  color: #D6B792 !important;
  top: 10px !important;
  width: 60px !important;
  height: auto !important;
}

.container-card-level {
  width: 60px !important;
}

.new-change-position {
  left: 404px !important;
}

.none-decoration {
  text-decoration: none;
}

.new-more-position {
  left: 351px !important;
}

.new-plus-position {
  left: 454px !important;
}

.new-change-color {
  color: #747474;
  left: 306px !important;
}

.new-barra-2{
  appearance: none;
  -webkit-appearance: none;
}

.new-background-container {
  height: 1422px;
  left: 1px;
  position: absolute;
  top: 0;
  width: 1920px;
}

.new-background {
  background-color: var(--new--hint-of-red);
  height: 1421px;
  left: 0;
  position: absolute;
  top: 1px;
  width: 1920px;
}

.new-background-1 {
  background-color: var(--new--sonic-silver);
  height: 1421px;
  left: 1487px;
  position: absolute;
  top: 0;
  width: 432px;
}

.new-rewards-chart-res {
  background-color: var(--new--sonic-silver);
  height: 441px;
  width: 65%;
  border-radius: 10px;
  display: flex;
  box-shadow: 0 4px 8px #0000000d;
  justify-content: center;
  border: 1px solid #000;
}

.new-group-78 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: 4px;
  margin-left: 13px;
  margin-right: 14px;
  margin-top: 23px;
  min-height: calc(100.00% - 27px);
  width: 668px;
}

.new-group-container {
  height: 116px;
  position: relative;
  width: 670px;
}

.new-group-252 {
  background-position: 50% 50%;
  background-size: cover;
  cursor: pointer;
  height: 14px;
  left: 141px;
  position: absolute;
  top: 0;
  width: 14px;
}

.show-hamburger-menu {
  display: none;
}

.new-overlap-group3 {
  height: 120px;
  margin-top: 10px;
  position: relative;
  width: 670px;
}



.new-activity-logs {
  height: 31px;
  left: 0;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 0;
  width: 320px;
}

.new-overlap-group1 {
  height: 92px;
  left: 0;
  position: absolute;
  top: 28px;
  width: 670px;
}

.new-overlap-group {
  height: 70px;
  left: 0;
  position: absolute;
  top: 9px;
  width: 667px;
}

.new-rectangle-31 {
  background-color: var(--new--sonic-silver);
  border-radius: 0px 5px 5px 0px;
  height: 41px;
  left: 34px;
  position: absolute;
  top: 14px;
  width: 633px;
}

.new-rectangle-32 {
  background-color: var(--new--cameo);
  border-radius: 0px 5px 5px 0px;
  height: 41px;
  left: 34px;
  position: absolute;
  top: 14px;
  width: 633px;
}

.new-path-64 {
  height: 70px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 70px;
}

.new-icon-star {
  height: 45px;
  left: 11px;
  object-fit: cover;
  position: absolute;
  top: 12px;
  width: 48px;
}

.new-lifetime-member {
  left: 236px;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  top: 32px;
  white-space: nowrap;
}

.new-price {
  left: 630px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.new-you-always-earn-10-rewards {
  left: 472px;
  letter-spacing: 0;
  line-height: 14px;
  position: absolute;
  text-align: right;
  top: 72px;
  white-space: nowrap;
}

.new-group-253 {
  background-position: 50% 50%;
  background-size: cover;
  cursor: pointer;
  height: 14px;
  left: 152px;
  position: absolute;
  top: 0;
  width: 14px;
}

.new-group-76 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  min-height: 159px;
  width: 667px;
}

.new-overlap-group4 {
  height: 31px;
  position: relative;
  width: 320px;
}

.new-group-254 {
  background-position: 50% 50%;
  background-size: cover;
  cursor: pointer;
  height: 14px;
  left: 99px;
  position: absolute;
  top: 1px;
  width: 14px;
}

.new-group-74 {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  margin-left: 3px;
  margin-top: 3px;
  min-height: 125px;
  width: 666px;
}

.new-group-container-1 {
  height: 102px;
  margin-right: 1.5px;
  position: relative;
  width: 665px;
}

.new-overlap-group-1 {
  height: 80px;
  left: 20px;
  position: absolute;
  top: 22px;
  width: 644px;
}

.new-rectangle-31-1 {
  border-radius: 0px 5px 5px 0px;
  height: 69px;
  left: 11px;
  position: absolute;
  top: 0;
  width: 633px;
}

.new-rectangle-32-1 {
  border-radius: 0px 10px 10px 0px;
  height: 69px;
  left: 11px;
  position: absolute;
  top: 0;
  width: 423px;
}

.new-rectangle-33 {
  border-radius: 10px;
  height: 69px;
  left: 160px;
  position: absolute;
  top: 0;
  width: 138px;
}

.new-icon-gift {
  height: 47px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 11px;
  width: 47px;
}

.new-rectangle-34 {
  background-color: var(--new--sonic-silver);
  border-radius: 0px 10px 10px 0px;
  height: 11px;
  left: 21px;
  position: absolute;
  top: 68px;
  width: 623px;
}

.new-rectangle-35 {
  background-color: var(--new--bizarre);
  height: 11px;
  left: 21px;
  position: absolute;
  top: 68px;
  width: 413px;
}

.new-group-77 {
  align-items: flex-start;
  display: flex;
  height: 102px;
  left: 0;
  min-width: 650px;
  position: absolute;
  top: 0;
}

.new-overlap-group1-1 {
  align-self: flex-end;
  height: 100px;
  position: relative;
  width: 516px;
}

.new-rectangle-31-2 {
  height: 69px;
  left: 31px;
  position: absolute;
  top: 20px;
  width: 485px;
}

.new-rectangle-32-2 {
  height: 69px;
  left: 31px;
  position: absolute;
  top: 20px;
  width: 314px;
}

.new-rectangle-33-1 {
  height: 69px;
  left: 206px;
  position: absolute;
  top: 20px;
  width: 155px;
}

.new-path-64-1 {
  height: 90px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 10px;
  width: 90px;
}

.new-icon-gift-1 {
  height: 47px;
  left: 20px;
  object-fit: cover;
  position: absolute;
  top: 32px;
  width: 47px;
}

.new-x20points {
  left: 116px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  text-align: center;
  top: 35px;
  white-space: nowrap;
}

.new-x30points {
  left: 255px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  text-align: center;
  top: 35px;
  white-space: nowrap;
}

.new-x50points {
  left: 411px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  text-align: center;
  top: 35px;
  white-space: nowrap;
}

.new-address {
  left: 108px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
}

.new-address-1 {
  left: 247px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
}

.new-address-2 {
  left: 403px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
}

.new-flex-col {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  margin-top: 1.5px;
  min-height: 84px;
  width: 118px;
}

.new-address-3 {
  letter-spacing: 0;
  line-height: 16px;
  min-height: 21px;
  min-width: 84px;
  text-align: center;
  white-space: nowrap;
}

.new-x75points-gift {
  letter-spacing: 0;
  line-height: 20px;
  margin-top: 14px;
  min-height: 49px;
  min-width: 118px;
  text-align: center;
  white-space: nowrap;
}

.new-address-4 {
  letter-spacing: 0;
  line-height: 14px;
  margin-top: 7px;
  min-height: 21px;
  min-width: 201px;
  text-align: right;
  white-space: nowrap;
}

.new-overlap-group6-res {
  height: 304px;
  left: 1098px;
}

.new-overlap-group-2 {
  align-items: flex-start;
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  height: 304px;
  left: 0;
  min-width: 357px;
  padding: 26.0px 81.4px;
  position: absolute;
  top: 0;
}

.new-share-link {
  display: flex;
  height: 27px;
  width: 192px;
}

.new-text-earning-this-month {
  display: flex;
  flex: 1;
  width: 192px;
}

.new-group-1 {
  display: flex;
  flex: 1;
  width: 194px;
}

.new-exclusive-item-access {
  flex: 1;
  letter-spacing: 0;
  line-height: 31px;
  margin-right: -2px;
  margin-top: -2px;
  text-align: center;
  white-space: nowrap;
  width: 194px;
}

.new-number-1-numbers {
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  line-height: 31px;
  position: absolute;
  top: 130px;
  font-size: 41px;
  left: 166px;
}

.new-number-2-numbers {
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  line-height: 31px;
  position: absolute;
  top: 130px;
  font-size: 41px;
  left: 152px;
}

.new-number-3-numbers {
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  line-height: 31px;
  position: absolute;
  top: 130px;
  font-size: 41px;
  left: 143px;
}

.new-number-4-numbers {
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  line-height: 31px;
  position: absolute;
  top: 130px;
  font-size: 38px;
  left: 133px;
}

.new-group-249-res {
  background-position: 50% 50%;
  background-size: cover;
  height: 129px;
  left: 125px;
  position: absolute;
  top: 65px;
  width: 108px;
}

.new-group-249-res-v2 {
  height: 129px;
  width: 108px;
  background-position: 50%;
  background-size: cover;
  margin-left: auto;
  margin-right: auto;
  font-size: 60px;
  justify-content: center;
  align-items: center;
  display: flex;
  font-family: 'Avenir-Medium';
}

.new-overlap-group7-res {
  height: 304px;
  left: 737px;
}

.new-overlap-group-3-res {
  height: 304px;
  min-width: 357px;
  background-position: 50%;
  background-size: cover;
  flex-direction: column;
  align-items: center;
  padding: 26px 101.4px;
  display: flex;
  justify-content: center;
}

.new-share-link-1 {
  display: flex;
  height: 27px;
  width: 152px;
}

.new-text-earning-this-month-1 {
  display: flex;
  flex: 1;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  width: 152px;
}

.new-group-1-1 {
  display: flex;
  flex: 1;
  width: 154px;
}

.new-x50-off-coupons {
  flex: 1;
  letter-spacing: 0;
  line-height: 31px;
  margin-right: -2px;
  margin-top: -2px;
  text-align: center;
  white-space: nowrap;
  width: 154px;
}

.new-group-248-res {
  height: 158px;
  min-width: 161px;
  background-position: 50%;
  background-size: cover;
  justify-content: center !important;
  align-items: flex-end;
  padding: 24.2px 64.9px;
  display: flex;
}

.new-group-248-res-v2 {
  height: 158px;
  min-width: 161px;
  background-position: 50%;
  background-size: cover;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 24.2px 64.9px;
  display: flex;
}
.new-number-1-1-numbers {
  letter-spacing: 0;
  line-height: 31px;
  min-height: 70px;
  min-width: 30px;
  text-align: center;
  white-space: nowrap;
}

.new-number-1-4-numbers {
  letter-spacing: 0;
  min-height: 70px;
  min-width: 30px;
  text-align: center;
  white-space: nowrap;
  line-height: 31px;
  font-size: 30px;
  padding: 4px 0px 0px 12px;
}

.new-number-1-2-numbers {
  letter-spacing: 0;
  min-height: 70px;
  min-width: 30px;
  text-align: center;
  white-space: nowrap;
  line-height: 31px;
  font-size: 40px;
  padding: 0px 0px 0px 11px;
}

.new-number-1-3-numbers {
  letter-spacing: 0;
  min-height: 70px;
  min-width: 30px;
  text-align: center;
  white-space: nowrap;
  padding: 2px 0 0 6px;
  font-size: 37px;
  line-height: 31px;
}

.button-withdraw-all-earning-2-respon {
  background-color: var(--new--bizarre);
  height: 35px;
  min-width: 146px;
  justify-content: center;
  align-items: center;
  padding: 2.4px 25px;
  display: flex;
  border-radius: 5px;
}


.new-redeem-in-cart-res {
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  line-height: 14px;
}

.new-overlap-group8-res {
  width: 333px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  height: 300px;
}

.button-rewards-fixed {
  display: flex;
  justify-content: center;
  text-decoration: none;
}

.button-withdraw-all-earning-2-res {
  background-color: var(--new--bizarre);
  height: 35px;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  padding: 2.4px 25px;
  display: flex;
  box-shadow: 2.78px 15.76px 40px #695f970d;
}

.button-withdraw-all-earning-2-res-v2 {
  background-color: var(--new--bizarre);
  height: 35px;
  min-width: 146px;
  justify-content: center;
  align-items: flex-start;
  padding: 2.4px 25px;
  display: flex;
  box-shadow: 2.78px 15.76px 40px #695f970d;
}

.new-card-rewards-res {
  background-color: var(--new--sonic-silver);
  height: 280px;
  min-width: 333px;
  border-radius: 10px;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 17px 91.1px;
  box-shadow: 0 4px 8px #0000000d;
}

.new-earning-report {
  display: flex;
  height: 222px;
  width: 146px;
}

.new-text-earning-this-month-2 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 100%;
  position: relative;
  width: 145.93px;
}

.new-available-points {
  letter-spacing: 0;
  line-height: 29px;
  margin-left: 0;
  margin-top: -1px;
  min-height: 29px;
  min-width: 142px;
  text-align: center;
  white-space: nowrap;
}

.new-group-247 {
  align-items: flex-start;
  display: flex;
  justify-content: flex-end;
  margin-left: 4px;
  margin-top: 10px;
  min-width: 139px;
}

.new-overlap-group-4 {
  height: 134px;
  margin-top: 0;
  position: relative;
  width: 139px;
}

.new-number-2-3-numbers {
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  line-height: 42px;
  position: absolute;
  top: 52px;
  left: 26px;
  font-size: 45px;
}

.new-number-2-1-numbers {
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  line-height: 42px;
  position: absolute;
  top: 52px;
  left: 54px;
  font-size: 45px;
}

.new-number-2-2-numbers {
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  line-height: 42px;
  position: relative;
  top: -33px;
  left: -3px;
  font-size: 45px;
}
.ajust-numbers{
  letter-spacing: 0 !important;
  text-align: center !important;
  white-space: nowrap !important;
  line-height: 42px !important;
  position: absolute !important;
  padding-left: 15px !important;
  top: 52px !important;
  left: 40px !important;
  font-size: 45px !important;
}

.new-number-2-4-numbers {
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  line-height: 42px;
  position: absolute;
  top: 52px;
  left: 12px;
  font-size: 45px;
}

.new-number-2-5-numbers {
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-size: 38px;
  line-height: 42px;
  position: absolute;
  top: 52px;
  left: 10px;
}

.new-group-246 {
  background-position: 50% 50%;
  background-size: cover;
  height: 134px;
  left: 0;
  position: absolute;
  top: 0;
  width: 139px;
}

.new-redeem-in-cart-1-res {
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  line-height: 14px;
}

.new-card-explainer-2-res {
  min-height: 174px;
  width: 182px;
  flex-direction: column;
  align-items: center;
  display: flex;
  z-index: 999;
}


.new-group-64 {
  align-items: flex-start;
  display: flex;
  margin-right: 1.92px;
  min-width: 60px;
  padding: 0.0px 0;
}

.new-path-container {
  height: 74px;
  position: relative;
  width: 60px;
}

.new-path-51 {
  height: 31px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 43px;
  width: 29px;
}

.new-path-52 {
  height: 31px;
  left: 31px;
  object-fit: cover;
  position: absolute;
  top: 43px;
  width: 29px;
}

.new-path-53 {
  height: 25px;
  left: 17px;
  object-fit: cover;
  position: absolute;
  top: 13px;
  width: 25px;
}

.new-path-54 {
  height: 52px;
  left: 4px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 52px;
}

.new-bonus-rewards {
  letter-spacing: 0;
  line-height: 18px;
  margin-top: 33px;
  min-height: 27px;
  min-width: 160px;
  text-align: center;
  white-space: nowrap;
}

.new-for-every-200-in-purchases {
  letter-spacing: 0;
  line-height: 12px;
  margin-top: 4px;
  min-height: 18px;
  min-width: 182px;
  text-align: center;
  white-space: nowrap;
}

.new-learn-more {
  cursor: pointer;
  letter-spacing: 0;
  line-height: 12px;
  margin-top: 5px;
  min-height: 18px;
  min-width: 80px;
  text-align: center;
  text-decoration: underline;
  white-space: nowrap;
}

.new-card-expainer-1-res {
  min-height: 174px;
  width: 272px;
  flex-direction: column;
  align-items: center;
  display: flex;
  z-index: 9;
}

.new-group-239 {
  align-items: flex-end;
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  height: 74px;
  justify-content: flex-end;
  margin-right: 1.08px;
  min-width: 77px;
  padding: 22.2px 17.9px;
}

.new-percent {
  color: var(--new--cameo-2);
  font-family: var(--new--font-family-montserrat);
  font-size: var(--new--font-size-l);
  font-weight: 700;
  letter-spacing: 0;
  line-height: 18px;
  min-height: 24px;
  min-width: 40px;
  text-align: center;
  white-space: nowrap;
}

.new-always-10-per-1-spent {
  letter-spacing: 0;
  line-height: 18px;
  margin-top: 33px;
  min-height: 27px;
  min-width: 244px;
  text-align: center;
  white-space: nowrap;
}

.bonus-rewads-res-separation {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.progress-container {
  display: flex;
  width: 65%;
  flex-direction: column;
  gap: 16px;
  padding: 22px;
  height: 441px;
  box-shadow: 0 2px 5px #38383829;
  justify-content: center;
}

.new-path-64-2-res {
  height: 70px;
  object-fit: cover;
  width: 70px;
  margin-top: -12px;
}

.posse-height {
  height: 200px !important;
}

.posse-height-progress{
  height: 75px !important;
}
.flex-months {
  display: flex;
  width: 100%;
}

.new-path-51-1-res {
  height: 16px;
  object-fit: cover;
  width: 15px;
  position: absolute;
  margin-top: -33px;
  margin-left: 20px;
}

.new-path-52-1-res {
  height: 16px;
  object-fit: cover;
  width: 15px;
  position: absolute;
  margin-top: -33px;
  margin-left: 36px;
}

.new-path-53-1-res {
  height: 13px;
  object-fit: cover;
  width: 13px;
  position: absolute;
  margin-left: 29px;
  margin-top: -48px;
}

.new-path-container-1-res {
  height: 37px;
  width: 30px;
  top: 16px;
  left: 19px;
}

.new-path-54-1-res {
  height: 26px;
  object-fit: cover;
  width: 26px;
  position: absolute;
  margin-top: -55px;
  margin-left: 22px;
}

.bonus-image-left {
  height: 0;
  margin-left: -10px;
}

.months-container {
  display: flex;
  justify-content: space-around;
  width: 100%;
  font-family: "Montserrat",Helvetica;
  font-size: 12px;
}

.months-posse-box-3{
  margin-left: 10%;
}

.months-posse {
  width: 25%;
  border-left: 2px solid #000;
  height: 71px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 18px;
  padding: 8px;
}

.show-generate-cart {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 80%;
  height: 80%;
}

.show-generate-cart-title {
  /* padding: 25px; */
  display: flex;
  font-size: 25px;
  font-weight: 600;
  font-family: 'Montserrat';
  text-align: center;
}
.buttons-generated {
  display: flex;
  gap: 10px;
  margin-top: 12px;
  display: flex;
  justify-content: center;
}
.contenedorLink{
  justify-content: space-between;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
  border-radius: 2px;
  display: flex;
  align-items: center;
  margin-top: 5%;
}
.linkEnlace{
  text-decoration: none;
  color: #000000;
}
.labelCopy{
  font-size: 20px;
  font-family: "Avenir-Heavy";
  font-style: normal;
  font-weight: 400;
}
.inputCopy{
  padding: 10px !important;
  border: 1px #000 solid !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  /* background-color: rgba(0,0,0,0); */
  color: #030303 !important;
  font-size: 20px !important;
  font-family: "Avenir-Heavy" !important;
  width: 100% !important;
}
.buttonCopy{
  border: none;
  color: #fff;
  margin-right: 5px;
  width: 40%;
  height: 70%;
  font-size: 20px;
  font-family: Arial,Helvetica,sans-serif;
  background-color: #000;
  border-radius: 5px;
}
.buttonCopy:hover{
  cursor: pointer;
}

@media screen and (max-width: 700px) {
  .months-posse{
    font-size: 12px !important;
  }
}
@media screen and (max-width: 450px) {
  .months-posse{
    font-size: 10px !important;
  }
}

.bonus-image-start {
  margin-left: -10px;
  height: 0;
}

.new-path-64-res {
  height: 70px;
  object-fit: cover;
  width: 70px;
  margin-top: -12px;
}

.posse-margin {
  height: 71px!important;
  margin-top: -75px!important;
}

.bonus-rewads-res-desc {
  display: flex;
  justify-content: flex-end;
  font-family: 'Avenir-Heavy';
  font-size: 13px;
  margin-left: 12%;
  color: #747474;
}

.container-gift-client {
  height: 0;
}

.new-icon-gift-res {
  height: 50px;
  object-fit: cover;
  width: 50px;
  margin-top: -40px;
  margin-left: -72px;
  margin-bottom: 26px;
}

.months-posse-20 {
  margin-left: 8%;
  border-left: 0 !important;
}

.new-icon-star-res {
  height: 45px;
  object-fit: cover;
  width: 48px;
  margin-left: -59px;
  margin-bottom: 13px;
}

.bonus-rewads-res-title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-family: 'Avenir-Heavy';
  font-size: 18px;
  margin-bottom: 6px;
}

.progress-bar-bonus {
  width: 100%;
  border: 2px solid #000;
  height: 41px;
  border-radius: 5px;
}

.progress-bar-content-bonus {
  width: 100%;
  height: 100%;
  font-family: 'Avenir-Heavy';
  color: #747474;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.new-path-64-res-gift {
  height: 96px;
  object-fit: cover;
  width: 96px;
  margin-top: -12px;
  margin-left: -18px;
}

.new-on-personal-referr {
  letter-spacing: 0;
  line-height: 12px;
  margin-top: 4px;
  min-height: 18px;
  min-width: 272px;
  white-space: nowrap;
}
.more-width-text {
  width: 100% ;
  text-align: left !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
}

.stylist-progress-content {
  color: #fff !important;
}

.affiliate-progress {
  color: #fff !important;
}

.background-progress {
  height: 37px;
  background-color: #d7b696;
  border: 0;
  margin-top: -37px;
}

@media (max-width: 1068px) {
  .progress-container {
    width: 90%;
  }
}

@media (max-width: 1033px) {
  .container-header-text-button {
    justify-content: center;
  }
}
@media (max-width: 688px) {
  .container-header-text-button {
    justify-content: center;
  }
}

@media (max-width: 440px) {
  .container-header-text-button {
    margin-top: 40px !important;
  }
}

@media (min-width: 796px) {
  .new-sidebar1-res {
    display: flex !important;
  }
}


@media (max-width: 394px) {
  .container-copy-text {
    margin-bottom: 30px;
  }
}

@media (max-width: 390px) {
  .new-rewards-chart-res {
    zoom: 50%;
  }
}

@media (max-width: 768px) {
  .new-rewards-chart-res {
    zoom: 100%;
  }
}

@media (min-width: 570px) and (max-width: 768px) {
  .new-rewards-chart-res {
    zoom: 75%;
  }
}

@media (max-width: 390px) {
  .months-posse-20 {
    margin-left: 15% !important;
    border-left: 0!important;
  }
}


@media (min-width: 390px) and (max-width: 570px) {
  .new-rewards-chart-res {
    zoom: 50%;
  }

  .months-posse-20 {
    margin-left: 15% !important;
    border-left: 0!important;
  }

  .new-activity-logs-2 {
    height: 40px !important;
    letter-spacing: 0;
    width: 360px !important;
    line-height: 44px !important;
    position: absolute;
    top: 10px !important;
    left: 40px !important;
    zoom: 35%;
  }

  .new-price-1 {
    letter-spacing: 0;
    white-space: nowrap;
    color: #747474;
    line-height: 24px;
    position: absolute;
    top: 77px !important;
    left: 640px !important;
    zoom: 50%;
  }

  .x25-more-to-10-bonus {
    letter-spacing: 0;
    text-align: right;
    white-space: nowrap;
    line-height: 14px;
    position: absolute;
    top: 190px !important;
    left: 507px !important;
    zoom: 35%;
  }
  .new-avenir-heavy-normal-sonic-silver-14px {
    zoom: 35% !important;
    font-size: 10px;
  }

  .new-avenir-medium-sonic-silver-14px-2 {
    zoom: 35% !important;
  }
}

@media (min-width: 1200px){
  .new-overlap-group9-res {
    grid-template-columns: 0.8fr 2.5fr 0.7fr;
  }
}

#ecwid_body {
  zoom: 75% !important;
}


@media (min-width: 796px) and (max-width: 1000px) {
  .new-rewards-chart-res {
    zoom: 100%;
  }
}

@media (max-width: 390px) {
  .new-rewards-chart-res {
    zoom: 50%;
  }
}

@media (max-width: 440px) {
  .new-overlap-group9-res { 
    padding: 0px;
  }

  .container-main-home {
    padding: 0 !important;
  }
}

@media (max-width: 796px) {
  .new-overlap-group9-res {
    grid-template-columns: 1fr !important;
  }

  .new-group-84 {
    width: 100% !important;
  }

  .none-decoration {
    width: 100%;
  }

  .new-group-82-4 {
    width: 100% !important;
  }

  .new-menus-res {
    flex-direction: column;
    flex: 1;
    align-items: flex-start;
    display: flex;
    justify-content: center;
    margin-left: auto !important;
    margin-right: auto !important;
    width: 70%;
  }

  .show-hamburger-menu {
    width: 25px;
    height: 25px;
    cursor: pointer;
    float: left;
    display: block;
    position: absolute;
    left: 10px;
    top: 10px;
    z-index: 999999999999999999;
  }

  .new-sidebar1-res {
    margin-bottom: 14px;
    position: absolute;
    z-index: 100000 !important;
    box-shadow: 0 8px 18px #38383829 !important;
    border-radius: 8px;
  }
}

.text-header-res {
  height: 70px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.text-header-hello {
  font-family: "Avenir",Helvetica;
  font-weight: 400;
  font-style: normal;
  font-size: 19px;
}

.text-header-bold{
  font-family: 'Avenir-Heavy',Helvetica;
  font-weight: bold;
  font-size: 23px;
}


.new-sidebar1 {
  background-color: var(--new--sonic-silver);
  box-shadow: 18px 4px 70px #00000005;
  display: flex;
  width: 345px;
  width: 100% !important;
  z-index: 99;
}

.new-sidebar1-res {
  background-color: var(--new--sonic-silver);
  z-index: 99;
  box-shadow: 18px 4px 70px #00000005;
  width: 100%!important;
  flex-direction: column;
}

.new-menus-res {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 30px;
  min-height: 380px;
}

.new-x01 {
  align-items: flex-end;
  background-color: var(--new--bizarre);
  border-radius: 10px;
  display: flex;
  height: 50px;
  width: 285px;
}

.new-group-300 {
  display: flex;
  flex: 1;
  height: 30px;
  margin-bottom: 7px;
  margin-left: 22.3px;
  margin-right: 30.0px;
  position: relative;
}

.new-dashboard {
  flex: 1;
  letter-spacing: 0;
  line-height: 18px;
  margin-bottom: -2px;
  margin-left: 28.3px;
  margin-right: -2.0px;
}

.new-x02 {
  align-items: flex-end;
  background-color: var(--new--sonic-silver);
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  height: 50px;
  margin-top: 14px;
  width: 285px;
}

.new-group-301 {
  display: flex;
  flex: 1;
  height: 30px;
  margin-bottom: 7px;
  margin-left: 25.2px;
  margin-right: 17px;
}

.new-proposal {
  background-position: 50% 50%;
  background-size: cover;
  height: 24.5px;
  width: 17.5px;
}

.new-projects {
  flex: 1;
  letter-spacing: 0;
  line-height: 18px;
  margin-bottom: -2px;
  margin-left: 31.2px;
  margin-right: -2px;
}

.new-group-83 {
  align-items: flex-end;
  background-color: var(--new--sonic-silver);
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  height: 50px;
  margin-top: 3px;
  width: 285px;
}

.new-group-82 {
  display: flex;
  flex: 1;
  height: 30px;
  margin-bottom: 6.0px;
  margin-left: 26px;
  margin-right: 21px;
}

.new-icon-user {
  height: 20.27px;
  margin-top: 2.0px;
  object-fit: cover;
  width: 16.89px;
}

.new-projects-1 {
  flex: 1;
  letter-spacing: 0;
  line-height: 18px;
  margin-bottom: -2px;
  margin-left: 31.1px;
  margin-right: -2px;
}

.new-group-84 {
  align-items: flex-end;
  background-color: var(--new--sonic-silver);
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  height: 50px;
  margin-top: 3px;
  position: relative;
  width: 285px;
}

.new-group-84-1 {
  align-items: flex-end;
  background-color: var(--new--sonic-silver);
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  height: 50px;
  margin-top: 4px;
  width: 285px;
}

.new-group-82-1 {
  display: flex;
  flex: 1;
  height: 30px;
  margin-bottom: 7.0px;
  margin-left: 21.1px;
  margin-right: 22px;
}

.new-group-302 {
  display: flex;
  flex: 1;
  width: 243.89px;
}

.new-group-281 {
  background-position: 50% 50%;
  background-size: cover;
  height: 22.84px;
  margin-top: 1px;
  width: 24.86px;
}

.new-projects-2 {
  flex: 1;
  letter-spacing: 0;
  line-height: 18px;
  margin-bottom: -2px;
  margin-left: 28.0px;
  margin-right: -2px;
}

.new-group-2 {
  align-items: flex-end;
  background-color: var(--new--sonic-silver);
  border-radius: 10px;
  display: flex;
  height: 50px;
  margin-top: 3px;
  width: 285px;
}
.new-group-2:hover{
  cursor: pointer;
}

.new-group-82-2 {
  display: flex;
  flex: 1;
  height: 30px;
  margin-bottom: 5.0px;
  margin-left: 27.1px;
  margin-right: 15px;
}

.new-group-303 {
  display: flex;
  flex: 1;
  width: 244.89px;
}

.new-group-292 {
  background-position: 50% 50%;
  background-size: cover;
  height: 18.9px;
  margin-top: 3px;
  width: 21.81px;
}

.new-projects-3 {
  flex: 1;
  letter-spacing: 0;
  line-height: 18px;
  margin-bottom: -2px;
  margin-left: 25.1px;
  margin-right: -2px;
}

.new-group-304 {
  display: flex;
  flex: 1;
  height: 30px;
  margin-bottom: 7px;
  margin-left: 27px;
}

.new-group-284 {
  background-position: 50% 50%;
  background-size: cover;
  height: 22.25px;
  margin-top: 1px;
  width: 22.25px;
}

.new-group-82-3 {
  display: flex;
  flex: 1;
  margin-left: 24.7px;
}

.new-projects-4 {
  flex: 1;
  letter-spacing: 0;
  line-height: 18px;
  margin-bottom: -2px;
  margin-right: -2px;
  width: 213px;
}

.new-header {
  align-items: center;
  display: flex;
  height: 136px;
  left: 0;
  min-width: 1443px;
  position: absolute;
  top: 0;
}

.new-group-112-res {
  height: 136px;
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.new-header-1 {
  align-items: flex-end;
  background-color: var(--new--sonic-silver);
  display: flex;
  flex: 1;
  width: 345px;
}

.new-group-268 {
  align-items: flex-end;
  display: flex;
  height: 89px;
  margin-left: 39px;
  min-width: 250px;
}

.new-overlap-group-5 {
  height: 107px;
  margin-bottom: -18px;
  position: relative;
  width: 250px;
}

.new-plunder-black-logo-250 {
  height: 27px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 250px;
}

.new-title {
  left: 0;
  letter-spacing: 0;
  line-height: 48px;
  position: absolute;
  text-shadow: 1px 1px 1px #000000;
  top: 25px;
  white-space: nowrap;
}

.new-welcome {
  align-items: flex-end;
  display: flex;
  height: 71px;
  margin-left: 55px;
  margin-top: 15.0px;
  min-width: 396px;
}

.new-overlap-group1-2 {
  height: 73px;
  margin-bottom: -2px;
  position: relative;
  width: 396px;
}

.new-welcome-1 {
  height: 27px;
  left: 0;
  letter-spacing: 0;
  line-height: 18px;
  position: absolute;
  top: 0;
  width: 396px;
}

.new-activity-logs-1 {
  color: var(--new--eerie-black);
  font-family: var(--new--font-family-avenir-heavy);
  font-size: 24px;
  font-weight: 400;
  height: 47px;
  left: 0;
  letter-spacing: 0;
  line-height: 24px;  position: absolute;
  top: 26px;
  width: 355px;
}

.new-copy-link {
  display: flex;
  height: 44px;
  justify-content: space-between;
  margin-bottom: 12.0px;
  margin-left: 50px;
  width: 406px;
}

.new-group-89 {
  align-items: flex-start;
  background-color: var(--new--sonic-silver);
  border-radius: 5px;
  display: flex;
  min-width: 450px;
  padding: 3.0px 16.3px;
}

.new-plunderdesigncomxxxxxxx {
  color: var(--new--black);
  font-family: var(--new--font-family-avenir-medium);
  font-size: 19px;
  font-weight: 500;
  letter-spacing: -0.47px;
  line-height: 35px;
  min-height: 28px;
  min-width: 232px;
  text-align: center;
  white-space: nowrap;
}

.new-x01-1 {
  align-items: flex-end;
  background-color: var(--new--bizarre);
  border-radius: 5px;
  display: flex;
  justify-content: flex-end;
  min-width: 135px;
  padding: 8px 18px;
}

.new-dashboard-1 {
  height: 25px;
  letter-spacing: 0;
  line-height: 18px;
  width: 98px;
}

.avalpt{
  flex: 1 !important;
  align-items: center !important;
  justify-content: center !important;
}

