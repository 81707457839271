.new-group-82-4 {
  display: flex;
  flex: 1;
  height: 30px;
  margin-bottom: 5.0px;
  margin-left: 26px;
  margin-right: 8px;
  position: relative;
  justify-content: center;
  align-items: center;
}

.new-group-82-4-tables {
  height: 30px;
  flex: 1;
  margin-bottom: 5px;
  margin-left: 26px;
  margin-right: 8px;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
}

.new-projects-5 {
  flex: 1;
  letter-spacing: 0;
  line-height: 18px;
  margin-bottom: -2px;
  margin-left: 30.8px;
  margin-right: -2px;
}

.new-group-82-4.group-308 {
  height: unset;
  margin-bottom: unset;
  margin-left: unset;
  margin-right: unset;
  width: 251px;
}

