.tablet-my-reports-distributers-only {
  align-items: flex-start;
  background-color: var(--hint-of-red);
  display: flex;
  width: 1024px;
}

.overlap-group3-9 {
  height: 1366px;
  position: relative;
  width: 1024px;
}

.overlap-group2-16 {
  height: 1366px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1024px;
}

.nav-4 {
  align-items: center;
  background-color: var(--white);
  display: flex;
  height: 120px;
  justify-content: flex-end;
  left: 0;
  min-width: 1024px;
  padding: 0 47px;
  position: absolute;
  top: 0;
}

.separator-5 {
  background-color: var(--black-haze);
  border-radius: 8px;
  height: 56px;
  margin-left: 367px;
  width: 1px;
}

.profile-15 {
  align-items: center;
  cursor: pointer;
  display: flex;
  margin-bottom: 1.0px;
  margin-left: 24px;
  min-width: 88px;
}

.overlap-group1-34 {
  align-items: flex-start;
  background-color: var(--silver-sand);
  border-radius: 14px;
  display: flex;
  height: 57px;
  justify-content: flex-end;
  min-width: 57px;
  padding: 15.0px 17.0px;
}

.icon-10 {
  height: 26px;
  object-fit: cover;
  width: 22px;
}

.profile-16 {
  background-position: 50% 50%;
  background-size: cover;
  height: 8px;
  margin-left: 16px;
  margin-top: 1.0px;
  width: 15px;
}

.sidebar-3 {
  background-color: var(--white);
  box-shadow: 18px 4px 70px #00000005;
  display: flex;
  flex-direction: column;
  height: 1366px;
  left: 0;
  position: absolute;
  top: 0;
  width: 120px;
}

.header-8 {
  align-items: center;
  background-color: var(--white);
  cursor: pointer;
  display: flex;
  flex: 1;
  max-height: 120px;
  min-width: 100%;
  padding: 0 32px;
}

.overlap-group1-35 {
  align-items: flex-start;
  background-color: var(--bizarre);
  border-radius: 14px;
  display: flex;
  height: 56px;
  min-width: 56px;
  padding: 12.7px 10.0px;
}

.path-71-3 {
  height: 30px;
  object-fit: cover;
  width: 35px;
}

.menus-7 {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  min-height: 261px;
}

.menu-3 {
  height: 24px;
  letter-spacing: 1.00px;
  line-height: 16px;
  width: 66px;
}

.dashboard-15 {
  align-items: flex-start;
  background-color: var(--gallery);
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  height: 50px;
  justify-content: flex-end;
  margin-right: 2px;
  margin-top: 23px;
  min-width: 74px;
  padding: 13.3px 25.3px;
  position: relative;
}

.email-5 {
  align-items: center;
  background-color: var(--white);
  cursor: pointer;
  display: flex;
  height: 50px;
  margin-right: 2px;
  margin-top: 14px;
  min-width: 74px;
  padding: 0 28.2px;
}

.proposal-7 {
  background-position: 50% 50%;
  background-size: cover;
  height: 24px;
  width: 18px;
}

.group-121-3 {
  align-items: flex-start;
  background-color: var(--white);
  cursor: pointer;
  display: flex;
  height: 50px;
  margin-right: 2px;
  min-width: 74px;
  padding: 15.1px 24.3px;
}

.users-3 {
  background-position: 50% 50%;
  background-size: cover;
  height: 20px;
  width: 25px;
}

.group-95-3 {
  align-items: flex-start;
  background-color: var(--white);
  display: flex;
  height: 50px;
  justify-content: flex-end;
  margin-right: 2px;
  min-width: 74px;
  padding: 12.9px 26.8px;
  position: relative;
}

.my-reports-1 {
  left: 179px;
  letter-spacing: 0;
  line-height: 34px;
  position: absolute;
  top: 160px;
  white-space: nowrap;
}

