.group-103-2 {
  align-items: center;
  display: flex;
  height: 49px;
  left: 0;
  min-width: 250px;
  position: absolute;
  top: 0;
}

.overlap-group-54 {
  height: 49px;
  margin-left: 11px;
  position: relative;
  width: 201px;
}

.group-102-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 0;
  min-height: 49px;
  position: absolute;
  top: 0;
  width: 161px;
}

.overlap-group-55 {
  height: 32px;
  position: relative;
  width: 161px;
}

.meeting-with-client-5 {
  height: 26px;
  left: 0;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  top: 0;
  width: 161px;
}

.x1000-1100-12 {
  height: 18px;
  letter-spacing: 0;
  line-height: 10px;
  margin-left: 1px;
  margin-top: 1px;
  width: 160px;
}

.group-103-2.group-104-2 {
  height: unset;
  left: unset;
  margin-top: 5px;
  position: relative;
  top: unset;
}

