.group-82-5,
.group-82-6,
.group-82-7,
.group-82-8,
.group-82-9 {
  display: flex;
  height: 27px;
  position: relative;
  width: 193px;
}

.projects-6,
.projects-7,
.projects-8,
.projects-9,
.projects-10 {
  flex: 1;
  letter-spacing: 0;
  line-height: 18px;
  margin-bottom: -2px;
  margin-left: 30.8px;
  margin-right: -2px;
}

