.button-save {
  align-items: flex-start;
  align-self: flex-end;
  background-color: var(--bizarre);
  border-radius: 10px;
  box-shadow: 1.39px 7.88px 29px #695f9724;
  display: flex;
  height: 40px;
  justify-content: flex-end;
  margin-right: 32px;
  margin-top: 72px;
  min-width: 110px;
  padding: 0 17px;
}

.shop-now-3 {
  letter-spacing: 0;
  line-height: 60px;
  margin-top: -10.43px;
  min-height: 21px;
  min-width: 74px;
  text-align: center;
  white-space: nowrap;
}

.button-save.button-save-1 {
  margin-top: 56px;
}

