.group-236 {
  display: flex;
  flex-direction: column;
  height: 209px;
  justify-content: space-between;
  width: 265px;
}

.set-font-family {
  font-family: "Montserrat",Helvetica;
  margin: auto;
}

.set-font-family-res {
  margin: auto;
  font-family: Montserrat,Helvetica;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.center-buttons {
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding-top: 5%;
  display: flex;
}
.content-after {
  width: 65% !important;
  height: 35% !important;
}

.btn-confirm {
  border: 0;
  background-color: D8B696;
  padding: 10px;
  color: black;
  font-size: 14px;
  cursor: pointer;
  opacity: 1;
}

.wait-title {
  font-size: 32px;
  text-align: center;
  margin: 30px 30px;
  font-family: 'Montserrat';
  font-weight: bold;
}

.paragraph-text {
  font-family: 'Montserrat';
  padding: 0px 25px 0px 25px;
}

.btn-confirm:hover {
  opacity: 0.5;
}

.btn-cancel {
  border: 0;
  background-color: black;
  padding: 10px;
  color: white;
  font-size: 14px;
  cursor: pointer;
  opacity: 1;
}

.btn-cancel:hover {
  opacity: 0.5;
}

.group-235 {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 135px;
  justify-content: space-between;
  max-height: 135px;
  position: relative;
}

.group-233 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  margin-right: 9.7px;
  max-height: 60px;
  min-width: calc(100.00% - 10px);
}

.overlap-group1 {
  height: 60px;
  position: relative;
  width: 255px;
}

.x24-june-1992 {
  left: 23px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 20px;
  white-space: nowrap;
}

.need-help-customer {
  flex: 1;
  height: 59px;
  letter-spacing: 0;
  line-height: 14px;
  margin-bottom: -9.5px;
  margin-left: 11px;
  margin-right: 9.7px;
  max-height: 59px;
  text-align: center;
  white-space: nowrap;
}


.show-checkbox {
  display: flex;
  justify-content: space-between;
  width:30%;
  margin: 10px 0 5px 0;
  }
  
  .color-check {
  background-color: #D8B696 !important; 
  }
  
  .info-help {
      margin-top: 10px;
      width: 65%;
    }
    
    
    
    .show-date-sub {
      width: 50%;
      padding: 10px;
      border: 0.5px solid #D8B696;
      border-radius: 8px;
      text-align: center;
      margin-bottom: 3px;
    }
    
    .date-sub-set {
      font-size: 24px;
      font-weight: bold;
      color: #ccc;
    }
    
    .container {
      display: block;
      position: relative;
      padding-left: 35px;
      margin-bottom: 12px;
      cursor: pointer;
      font-size: 22px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
    
    /* Hide the browser's default radio button */
    .container input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
    
    /* Create a custom radio button */
    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 25px;
      width: 25px;
      background-color: #D8B696 !important;
      border-radius: 50%;
    }
    
    /* On mouse-over, add a grey background color */
    .container:hover input ~ .checkmark {
      background-color: #ccc;
    }
    
    /* When the radio button is checked, add a blue background */
    .container input:checked ~ .checkmark {
      background-color: #2196F3;
    }
    
    /* Create the indicator (the dot/circle - hidden when not checked) */
    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }
    
    /* Show the indicator (dot/circle) when checked */
    .container input:checked ~ .checkmark:after {
      display: block;
    }
    
    /* Style the indicator (dot/circle) */
    .container .checkmark:after {
      top: 9px;
      left: 9px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: white;
    }
    

    .desktop-my-account {
      align-items: flex-start;
      display: flex;
      height: 1421px;
      overflow: hidden;
      width: 1920px;
    }
    
    .overlap-group5-2 {
      height: 1439px;
      position: relative;
      width: 1920px;
    }
    
    .profile-box {
      width: 100%;
      flex-direction: row;
      justify-content: center;
      display: flex;
      gap: 28px;
      flex-wrap: wrap;
    }
    
    .background-4 {
      align-items: flex-start;
      background-color: var(--hint-of-red);
      display: flex;
      flex-direction: column;
      left: 0;
      min-height: 1421px;
      padding: 236px 410px;
      position: absolute;
      top: 0;
      width: 1920px;
    }
    
    .group-127 {
      align-items: center;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      min-height: 315px;
      position: relative;
      width: 275px;
    }
    
    .super-admin-6 {
      height: 26px;
      letter-spacing: 0;
      line-height: 14px;
      margin-left: 2.0px;
      margin-top: 16px;
      text-align: center;
      width: 107px;
    }
    
    .group-23 {
      display: flex;
      height: 417px;
      margin-top: 40px;
      width: 509px;
    }
    
    .group-123 {
      align-items: center;
      display: flex;
      flex: 1;
      min-width: 511px;
    }
    
    .overlap-group1-15 {
      height: 417px;
      position: relative;
      width: 445px;
    }
    
    .big-title {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      left: 0;
      min-height: 417px;
      position: absolute;
      top: 0;
      width: 445px;
    }
    
    .dashboard-5 {
      height: 54px;
      letter-spacing: 0;
      line-height: 38px;
      margin-top: -2px;
      width: 330px;
    }
    
    .level-status-custo {
      letter-spacing: 0;
      line-height: 26px;
      margin-top: 23px;
      min-height: 78px;
      white-space: nowrap;
    }
    
    .phone {
      letter-spacing: 0;
      line-height: 26px;
      margin-left: 34px;
      margin-top: 65px;
      min-height: 26px;
      white-space: nowrap;
    }
    
    .email {
      color: #7a86a1;
      font-family: var(--font-family-manrope);
      font-size: var(--font-size-xxxl);
      letter-spacing: 0;
      line-height: 26px;
      margin-left: 34px;
      margin-top: 55px;
      min-height: 26px;
      white-space: nowrap;
    }
    
    .overlap-group-38 {
      height: 34px;
      margin-top: 52px;
      position: relative;
      width: 445px;
    }
    
    .dashboard-6 {
      height: 33px;
      left: 0;
      letter-spacing: 0;
      line-height: 38px;
      position: absolute;
      top: 0;
      white-space: nowrap;
      width: 330px;
    }
    
    .plunderdesigncomweb_alias {
      left: 166px;
      letter-spacing: 0;
      line-height: 26px;
      position: absolute;
      top: 8px;
      white-space: nowrap;
    }
    
    .icon-call {
      height: 22px;
      left: 0;
      object-fit: cover;
      position: absolute;
      top: 221px;
      width: 22px;
    }
    
    .email-2-1 {
      height: 14px;
      left: 0;
      object-fit: cover;
      position: absolute;
      top: 305px;
      width: 20px;
    }
    
    .super-admin-container {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      margin-left: 7px;
      margin-top: 145.0px;
      min-height: 98px;
      width: 59px;
    }
    
    .super-admin-7 {
      cursor: pointer;
      height: 28px;
      letter-spacing: 0;
      line-height: 14px;
      width: 59px;
    }
    
    .super-admin-8 {
      cursor: pointer;
      height: 28px;
      letter-spacing: 0;
      line-height: 14px;
      margin-top: 42px;
      width: 59px;
    }
    
    .group-113-2 {
      align-items: center;
      background-color: var(--white);
      display: flex;
      height: 120px;
      justify-content: flex-end;
      left: 0;
      min-width: 1920px;
      padding: 0 50px;
      position: absolute;
      top: 0;
    }
    
    .profile-6 {
      align-items: center;
      cursor: pointer;
      display: flex;
      margin-bottom: 1.0px;
      margin-left: 68px;
      min-width: 223px;
      position: relative;
    }
    
    .overlap-group1-16 {
      align-items: flex-start;
      background-color: var(--silver-sand);
      border-radius: 14px;
      display: flex;
      height: 57px;
      justify-content: flex-end;
      min-width: 57px;
      padding: 15.0px 17.0px;
    }
    
    .icon-5 {
      height: 26px;
      object-fit: cover;
      width: 22px;
    }
    
    .overlap-group4-6 {
      height: 1455px;
      left: 0;
      position: absolute;
      top: 0;
      width: 345px;
    }
    
    .footer-2 {
      background-color: var(--white);
      box-shadow: 18px 4px 70px #00000005;
      height: 1421px;
      left: 0;
      position: absolute;
      top: 0;
      width: 345px;
    }
    
    .menus-2 {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      left: 30px;
      min-height: 220px;
      position: absolute;
      top: 120px;
      width: 285px;
    }
    
    .x01-5 {
      align-items: center;
      border-radius: 10px;
      cursor: pointer;
      display: flex;
      height: 50px;
      min-width: 285px;
      padding: 9px 22.3px;
      position: relative;
    }
    
    .dashboard-7 {
      align-self: flex-end;
      height: 31px;
      letter-spacing: 0;
      line-height: 18px;
      margin-left: 28px;
      width: 98px;
    }
    
    .x02-2 {
      align-items: center;
      background-color: var(--white);
      border-radius: 10px;
      cursor: pointer;
      display: flex;
      height: 50px;
      margin-top: 14px;
      min-width: 285px;
      padding: 9px 25.2px;
    }
    
    .proposal-2 {
      background-position: 50% 50%;
      background-size: cover;
      height: 24px;
      width: 18px;
    }
    
    .projects-12 {
      align-self: flex-end;
      height: 29px;
      letter-spacing: 0;
      line-height: 18px;
      margin-left: 31px;
      width: 145px;
    }
    
    .group-83-2 {
      align-items: flex-end;
      background-color: var(--bizarre);
      border-radius: 10px;
      display: flex;
      height: 50px;
      margin-top: 6px;
      min-width: 285px;
      padding: 11px 26px;
      position: relative;
    }
    
    .group-84-2 {
      align-items: flex-end;
      background-color: var(--white);
      border-radius: 10px;
      cursor: pointer;
      display: flex;
      height: 50px;
      min-width: 285px;
      padding: 11px 26px;
      position: relative;
    }
    
    .overlap-group1-17 {
      align-items: flex-end;
      background-position: 50% 50%;
      background-size: cover;
      border-radius: 10px;
      display: flex;
      height: 600px;
      left: 30px;
      min-width: 285px;
      padding: 17.2px 14px;
      position: absolute;
      top: 382px;
    }
    
    .button-withdraw-all-earning-11 {
      align-items: flex-start;
      background-color: var(--bizarre);
      border-radius: 10px;
      box-shadow: 2.78px 15.76px 40px #695f970d;
      display: flex;
      height: 40px;
      justify-content: flex-end;
      min-width: 108px;
      padding: 0 9.7px;
    }
    
    .learn-more-4 {
      letter-spacing: 0.08px;
      line-height: 49px;
      margin-top: -3.5px;
      min-height: 20px;
      min-width: 86px;
      text-align: center;
      white-space: nowrap;
    }
    
    .overlap-group2-9 {
      background-position: 50% 50%;
      background-size: cover;
      height: 224px;
      left: 18px;
      position: absolute;
      top: 1001px;
      width: 309px;
    }
    
    .overlap-group1-18 {
      height: 200px;
      left: 27px;
      position: absolute;
      top: 8px;
      width: 270px;
    }
    
    .your-image-here-4 {
      height: 191px;
      left: 79px;
      object-fit: cover;
      position: absolute;
      top: 7px;
      width: 191px;
    }
    
    .sleepangle-4 {
      height: 200px;
      left: 142px;
      object-fit: cover;
      position: absolute;
      top: 0;
      width: 128px;
    }
    
    .group-25-4 {
      align-items: flex-start;
      cursor: pointer;
      display: flex;
      height: 40px;
      justify-content: flex-end;
      left: 0;
      min-width: 114px;
      position: absolute;
      top: 147px;
    }
    
    .overlap-group-39 {
      align-items: flex-start;
      background-position: 50% 50%;
      background-size: cover;
      display: flex;
      height: 160px;
      margin-top: -44.24px;
      min-width: 234px;
      padding: 40.7px 86.0px;
    }
    
    .explore-2 {
      letter-spacing: 0.08px;
      line-height: 49px;
      min-height: 20px;
      min-width: 58px;
      text-align: center;
      white-space: nowrap;
    }
    
    .ongoing-4 {
      height: 46px;
      left: 0;
      letter-spacing: 0;
      line-height: 12px;
      position: absolute;
      top: 61px;
      width: 114px;
    }
    
    .just-in-2 {
      left: 27px;
      letter-spacing: 0;
      line-height: 26px;
      position: absolute;
      top: 34px;
      white-space: nowrap;
    }
    
    .overlap-group3-5 {
      background-position: 50% 50%;
      background-size: cover;
      height: 224px;
      left: 18px;
      position: absolute;
      top: 1231px;
      width: 309px;
    }
    
    .your-image-here-5 {
      height: 191px;
      left: 106px;
      object-fit: cover;
      position: absolute;
      top: 15px;
      width: 191px;
    }
    
    .sleepangle-5 {
      height: 200px;
      left: 169px;
      object-fit: cover;
      position: absolute;
      top: 8px;
      width: 128px;
    }
    
    .group-25-5 {
      align-items: flex-start;
      cursor: pointer;
      display: flex;
      height: 40px;
      justify-content: flex-end;
      left: 24px;
      min-width: 114px;
      position: absolute;
      top: 158px;
    }
    
    .overlap-group-40 {
      align-items: flex-start;
      background-position: 50% 50%;
      background-size: cover;
      display: flex;
      height: 160px;
      margin-top: -44.24px;
      min-width: 234px;
      padding: 40.7px 80.0px;
    }
    
    .subscribe-2 {
      letter-spacing: 0.08px;
      line-height: 49px;
      min-height: 20px;
      min-width: 70px;
      text-align: center;
      white-space: nowrap;
    }
    
    .posse-box-2 {
      left: 24px;
      letter-spacing: 0;
      line-height: 26px;
      position: absolute;
      top: 50px;
      white-space: nowrap;
    }
    
    .ongoing-5 {
      height: 46px;
      left: 24px;
      letter-spacing: 0;
      line-height: 12px;
      position: absolute;
      top: 84px;
      width: 133px;
    }
    
    .my-account {
      left: 408px;
      letter-spacing: 0;
      line-height: 44px;
      position: absolute;
      top: 156px;
      white-space: nowrap;
    }
    