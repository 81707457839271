.points-progress-bar {
  background-color: var(--new--black);
  display: flex;
  height: 35px;
  left: 388px;
  position: absolute;
  top: 692px;
  width: 1055px;
}

.new-activity-logs-4 {
  letter-spacing: 0;
  line-height: 20px;
  margin-bottom: 2px;
  margin-left: 13px;
  margin-top: 4px;
  width: 400px;
}

.points-progress-bar.points-bar {
  top: 328px;
}

.points-progress-bar.points-bar .new-activity-logs-4 {
  margin-bottom: -1px;
  margin-top: 3px;
  width: 299px;
}

