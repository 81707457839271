.tablet-edit-email {
  align-items: flex-start;
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  height: 1366px;
  padding: 140px 106px;
  width: 1024px;
}

.group-22-1 {
  align-items: flex-start;
  background-color: var(--cultured-pearl);
  border-radius: 10px;
  box-shadow: 0px 3px 6px #00000029;
  display: flex;
  flex-direction: column;
  margin-left: 376px;
  min-height: 200px;
  padding: 22.0px 21.4px;
  width: 436px;
}

.active-4 {
  display: flex;
  height: 83px;
  margin-top: 14px;
  width: 364px;
}

.input-4 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  min-width: 363.68px;
}

.overlap-group-45 {
  height: 83px;
  position: relative;
  width: 364px;
}

.background-5 {
  border-radius: 14px;
  height: 60px;
  left: 0;
  position: absolute;
  top: 23px;
  width: 364px;
}

.input-box-4 {
  height: 24px;
  left: 1px;
  letter-spacing: 1.00px;
  line-height: 16px;
  position: absolute;
  top: 0;
  width: 151px;
}

.input-here-8 {
  height: 39px;
  left: 22px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 34px;
  width: 156px;
}

.flex-row-8 {
  align-items: center;
  display: flex;
  margin-left: 0;
  margin-top: 20px;
  min-width: 228px;
}

.button-withdraw-all-earning-13 {
  align-items: flex-start;
  background-color: var(--bizarre);
  border-radius: 10px;
  box-shadow: 2.78px 15.76px 40px #695f970d;
  display: flex;
  height: 40px;
  justify-content: flex-end;
  min-width: 143px;
  padding: 0 26.4px;
}

.save-changes-2 {
  letter-spacing: 0;
  line-height: 49px;
  margin-top: -4.7px;
  min-height: 20px;
  min-width: 88px;
  text-align: center;
  white-space: nowrap;
}

.super-admin-10 {
  height: 28px;
  letter-spacing: 0;
  line-height: 14px;
  margin-left: 26px;
  margin-top: 1.59px;
  width: 59px;
}

