.group-88,
.group-88-1 {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 4px 8px #0000000d;
  display: flex;
  height: 549px;
  left: 394px;
  position: absolute;
  top: 856px;
  width: 695px;
}

.center-text {
  text-align: center !important;
  font-size: 30px !important;
  margin: 100px;
  margin-top: 275px !important;
}

.group-78,
.group-78-1 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: 7px;
  margin-left: 13px;
  margin-right: 14px;
  margin-top: 13px;
  min-height: calc(100.00% - 20px);
  width: 668px;
}

.group-72,
.group-72-1 {
  height: 116px;
  position: relative;
  width: 670px;
}

.overlap-group-28,
.overlap-group-30 {
  height: 92px;
  left: 0;
  position: absolute;
  top: 29px;
  width: 670px;
}

.overlap-group-29,
.overlap-group-31 {
  height: 70px;
  left: 0;
  position: absolute;
  top: 8px;
  width: 667px;
}

.rectangle-31,
.rectangle-31-1 {
  height: 41px;
  left: 34px;
  position: absolute;
  top: 14px;
  width: 633px;
}

.rectangle-32,
.rectangle-32-5 {
  background-color: white;
  height: 41px;
  left: 34px;
  position: absolute;
  top: 14px;
  width: 96%;
  appearance: none;
  -webkit-appearance: none;
}

.rectangle-32::-webkit-progress-bar{
  background-color: white;
}

.rectangle-32::-webkit-progress-value{
  background-color: #ccdce4;
}

.barra-2{
  appearance: none;
  -webkit-appearance: none;
}

.barra-2::-webkit-progress-bar{
  background-color: white;
}

.barra-2::-webkit-progress-value{
  background-color: #cfe1d9;
}

.barra-3 {
  appearance: none;
  -webkit-appearance: none;
}

.barra-3::-webkit-progress-bar {
  background-color: white;
}

.barra-3::-webkit-progress-value {
  background-color: #D8B696;
}

.path-64,
.path-64-1 {
  height: 70px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 70px;
}

.icon-star-1,
.path-65 {
  height: 45px;
  left: 11px;
  object-fit: cover;
  position: absolute;
  top: 12px;
  width: 48px;
}

.price,
.price-2 {
  left: 236px;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  top: 30px;
  white-space: nowrap;
}

.price-1,
.price-3 {
  left: 630px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.x25-away-from-10-bonus-points,
.x25-away-from-10-bonus-points-1 {
  left: 467px;
  letter-spacing: 0;
  line-height: 14px;
  position: absolute;
  text-align: right;
  top: 70px;
  white-space: nowrap;
}

.activity-logs-5,
.activity-logs-6 {
  height: 31px;
  left: 0;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 0;
  width: 320px;
}

.group-73,
.group-73-1 {
  height: 116px;
  margin-top: 10px;
  position: relative;
  width: 670px;
}

.rectangle-32-1,
.rectangle-32-6 {
  background-color: var(--surf-crest);
  height: 41px;
  left: 34px;
  position: absolute;
  top: 14px;
  width: 96%;
}

.icon-label-1,
.path-66 {
  height: 39px;
  left: 14px;
  object-fit: cover;
  position: absolute;
  top: 16px;
  width: 40px;
}

.group-75,
.group-75-1 {
  align-items: flex-end;
  display: flex;
  height: 116px;
  margin-top: 10px;
  min-width: 668px;
}

.overlap-group3-2,
.overlap-group3-3 {
  height: 120px;
  margin-bottom: -4.5px;
  position: relative;
  width: 670px;
}

.overlap-group1-8,
.overlap-group1-10 {
  height: 92px;
  left: 0;
  position: absolute;
  top: 28px;
  width: 670px;
}

.overlap-group-32,
.overlap-group-34 {
  height: 70px;
  left: 0;
  position: absolute;
  top: 9px;
  width: 667px;
}

.rectangle-32-2,
.rectangle-32-7 {
  background-color: var(--cameo);
  height: 41px;
  left: 34px;
  position: absolute;
  top: 14px;
  width: 96%;
}

.icon-heart,
.path-67 {
  height: 38px;
  left: 15px;
  object-fit: cover;
  position: absolute;
  top: 16px;
  width: 40px;
}

.price-4,
.price-5 {
  left: 236px;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  top: 32px;
  white-space: nowrap;
}

.x25-away-from-10-bonus-points-2,
.x25-away-from-10-bonus-points-3 {
  left: 467px;
  letter-spacing: 0;
  line-height: 14px;
  position: absolute;
  text-align: right;
  top: 72px;
  white-space: nowrap;
}

.group-76,
.group-76-1 {
  align-items: flex-start;
  display: flex;
  margin-top: 13px;
  min-width: 667px;
}

.overlap-group4-3,
.overlap-group4-4 {
  height: 148px;
  position: relative;
  width: 669px;
}

.group-74,
.group-74-1 {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  left: 3px;
  min-height: 142px;
  position: absolute;
  top: 6px;
  width: 666px;
}

.overlap-group2-6,
.overlap-group2-7 {
  height: 117px;
  margin-top: 2px;
  position: relative;
  width: 666px;
}

.overlap-group-33,
.overlap-group-35 {
  height: 80px;
  left: 20px;
  position: absolute;
  top: 37px;
  width: 644px;
}

.rectangle-31-2,
.rectangle-31-4 {
  height: 69px;
  left: 11px;
  position: absolute;
  top: 0;
  width: 633px;
}

.rectangle-32-3,
.rectangle-32-8 {
  height: 69px;
  left: 11px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 423px;
}

.rectangle-33,
.rectangle-33-2 {
  background-color: var(--bizarre);
  border-radius: 10px;
  height: 69px;
  left: 160px;
  position: absolute;
  top: 0;
  width: 138px;
}

.icon-gift,
.path-68 {
  height: 47px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 11px;
  width: 47px;
}

.rectangle-34,
.rectangle-34-1 {
  height: 11px;
  left: 21px;
  object-fit: cover;
  position: absolute;
  top: 68px;
  width: 623px;
}

.rectangle-35,
.rectangle-35-1 {
  background-color: var(--cameo);
  height: 11px;
  left: 21px;
  position: absolute;
  top: 68px;
  width: 413px;
}

.x12months,
.x12months-1 {
  left: 602px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  text-align: center;
  top: 1px;
  white-space: nowrap;
}

.group-77,
.group-77-1 {
  align-items: center;
  display: flex;
  height: 90px;
  left: 0;
  min-width: 634px;
  position: absolute;
  top: 27px;
}

.overlap-group1-9,
.overlap-group1-11 {
  height: 90px;
  position: relative;
  width: 516px;
}

.rectangle-31-3,
.rectangle-31-5 {
  background-color: var(--white);
  height: 69px;
  left: 31px;
  position: absolute;
  top: 10px;
  width: 485px;
}

.rectangle-32-4,
.rectangle-32-9 {
  background-color: var(--bizarre);
  height: 69px;
  left: 31px;
  position: absolute;
  top: 10px;
  width: 314px;
}

.rectangle-33-1,
.rectangle-33-3 {
  background-color: var(--bizarre);
  height: 69px;
  left: 206px;
  position: absolute;
  top: 10px;
  width: 155px;
}

.path-64-2,
.path-64-3 {
  height: 90px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 90px;
}

.icon-gift-1,
.path-68-1 {
  height: 47px;
  left: 20px;
  object-fit: cover;
  position: absolute;
  top: 22px;
  width: 47px;
}

.x20bonus-points,
.x20bonus-points-1 {
  left: 103px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  text-align: center;
  top: 29px;
  white-space: nowrap;
}

.x30bonus-points,
.x30bonus-points-1 {
  left: 242px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  text-align: center;
  top: 29px;
  white-space: nowrap;
}

.x50bonus-points,
.x50bonus-points-1 {
  left: 398px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  text-align: center;
  top: 29px;
  white-space: nowrap;
}

.x75bonus-points-gifts,
.x75bonus-points-gifts-1 {
  letter-spacing: 0;
  line-height: 16px;
  margin-left: 32px;
  margin-top: 4.76px;
  min-height: 53px;
  min-width: 86px;
  text-align: center;
  white-space: nowrap;
}

.x9months,
.x9months-1 {
  left: 482px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  text-align: center;
  top: 1px;
  white-space: nowrap;
}

.x6months,
.x6months-1 {
  left: 328px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  text-align: center;
  top: 1px;
  white-space: nowrap;
}

.x3months,
.x3months-1 {
  left: 176px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
}

.address-4,
.address-5 {
  letter-spacing: 0;
  line-height: 14px;
  margin-top: 7px;
  min-height: 21px;
  min-width: 198px;
  text-align: right;
  white-space: nowrap;
}

