.x01,
.x01-1 {
  align-items: center;
  border-radius: 10px;
  display: flex;
  height: 50px;
  min-width: 285px;
  padding: 9px 22.3px;
  position: relative;
  background-color: var(--bizarre);
}

.dashboard,
.dashboard-1 {
  align-self: flex-end;
  height: 31px;
  letter-spacing: 0;
  line-height: 18px;
  margin-left: 28px;
  width: 98px;
}

