.overlap-group7-2-item-1 {
  align-items: center;
  display: flex;
  height: 51px;
  left: 0;
  min-width: 250px;
  position: absolute;
  top: 0;
}

.overlap-group-56 {
  height: 51px;
  margin-left: 11px;
  position: relative;
  width: 201px;
}

.due-date-5 {
  display: flex;
  height: 25px;
  left: 117px;
  position: absolute;
  top: 13px;
  width: 84px;
}

.x1000-1100-13 {
  flex: 1;
  letter-spacing: 0;
  line-height: 12px;
  margin-bottom: -2px;
  margin-right: -2px;
  text-align: right;
  width: 84px;
}

.group-97-4 {
  height: 51px;
  left: 0;
  position: absolute;
  top: 0;
  width: 163px;
}

.overlap-group-57 {
  height: 33px;
  left: 0;
  position: absolute;
  top: 0;
  width: 163px;
}

.meeting-with-client-6 {
  height: 26px;
  left: 0;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  top: 0;
  width: 163px;
}

.x1000-1100-14 {
  height: 20px;
  left: 1px;
  letter-spacing: 0;
  line-height: 10px;
  position: absolute;
  top: 33px;
  width: 162px;
}

.overlap-group7-2-item-1.x3 {
  align-items: center;
  display: flex;
  margin-top: 8px;
  min-width: 250px;
  position: relative;
}

