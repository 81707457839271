.date-4-1 {
  align-items: flex-end;
  display: flex;
  height: 18px;
  left: 1px;
  position: absolute;
  top: 26px;
  width: 130px;
}

.calendar-2 {
  background-image: url(/static/img/vector-10@1x.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 13.55px;
  margin-bottom: 1.3px;
  width: 15.01px;
}

.date-5 {
  flex: 1;
  letter-spacing: 0;
  line-height: 10px;
  margin-bottom: -2px;
  margin-left: 8.0px;
  margin-right: -2.0px;
}

.date-4-1.date-4 .calendar-2,
.date-4-1.date-6 .calendar-2 {
  margin-bottom: 1.4px;
}

.date-4-1.date-8,
.date-4-1.date-10 {
  align-items: flex-end;
  display: flex;
  height: 19px;
  left: 1px;
  position: absolute;
  top: 25px;
  width: 130px;
}

.date-4-1.date-8 .calendar-2,
.date-4-1.date-10 .calendar-2 {
  margin-bottom: 1.6px;
}

.date-4-1.date-8 .date-5,
.date-4-1.date-10 .date-5 {
  margin-right: -2px;
}

