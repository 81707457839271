.group-40 {
  display: flex;
  height: 51px;
  left: 31px;
  position: absolute;
  top: 157px;
  width: 180px;
}

.path-40 {
  height: 22.25px;
  margin-left: 0;
  margin-top: 3.0px;
  object-fit: cover;
  width: 22.25px;
}

.consectetur-adipiscing {
  flex: 1;
  letter-spacing: 0;
  line-height: 20px;
  margin-bottom: -2px;
  margin-left: 15.7px;
  margin-right: -2px;
}

.group-40.group-39 {
  left: 793px;
  top: 267px;
}

