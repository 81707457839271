@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");
@import url("https://fonts.googleapis.com/css?family=Manrope:400,700,600,800|Cairo:700,600|Open+Sans:400,700,600|Poppins:700,500|Montserrat:400,700,500");
/* The following line is used to measure usage of this code in production. For more info see our usage billing page */
@import url("https://px.animaapp.com/601b4eac70d7b1a36d6683c6.601b4eae32c268e0fee747ef.siG2jE9.rcp.png");

@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");
@import url("https://fonts.googleapis.com/css?family=Montserrat:700,500|Manrope:400,700,600,800|Open+Sans:400,700,600|Poppins:700,500");
/* The following line is used to measure usage of this code in production. For more info see our usage billing page */
@import url("https://px.animaapp.com/601b4eac70d7b1a36d6683c6.601b4eae32c268e0fee747ef.9vr0ymI.rcp.png");


@font-face {
  font-family: "Avenir-Heavy";
  font-style: normal;
  font-weight: 400;
  src: url("https://fonts.animaapp.com/Avenir-Heavy") format("truetype");
}


@font-face {
  font-family: "Avenir-Black";
  font-style: normal;
  font-weight: 900;
  src: url("static/fonts/Avenir-Black-03.ttf") format("truetype");
}
@font-face {
  font-family: "Avenir-Heavy";
  font-style: normal;
  font-weight: 400;
  src: url("static/fonts/AvenirLTStd-Heavy.otf") format("opentype");
}
@font-face {
  font-family: "Avenir-Book";
  font-style: normal;
  font-weight: 400;
  src: url("static/fonts/AvenirArabic-Book.otf") format("opentype");
}
@font-face {
  font-family: "Avenir-Medium";
  font-style: normal;
  font-weight: 500;
  src: url("static/fonts/AvenirLTStd-Medium.otf") format("opentype");
}
@font-face {
  font-family: "Avenir-HeavyOblique";
  font-style: normal;
  font-weight: 400;
  src: url("static/fonts/Avenir-TTF-HeavyOblique.ttf") format("truetype");
}
@font-face {
  font-family: "BrittanySignature-Regular";
  font-style: normal;
  font-weight: 400;
  src: url("static/fonts/BrittanySignature.ttf") format("truetype");
}


@font-face {
  font-family: "Avenir-Black";
  font-style: normal;
  font-weight: 900;
  src: url("static/fonts/Avenir-Black-03.ttf") format("truetype");
}
@font-face {
  font-family: "Avenir-Book";
  font-style: normal;
  font-weight: 400;
  src: url("static/fonts/AvenirArabic-Book.otf") format("opentype");
}
@font-face {
  font-family: "Avenir-Heavy";
  font-style: normal;
  font-weight: 400;
  src: url("static/fonts/Avenir-Heavy-05.ttf") format("truetype");
}
@font-face {
  font-family: "Avenir-Medium";
  font-style: normal;
  font-weight: 500;
  src: url("static/fonts/AvenirLTStd-Medium.otf") format("opentype");
}
@font-face {
  font-family: "Bodoni72-Bold";
  font-style: normal;
  font-weight: 700;
  src: url("static/fonts/Bodoni 72.ttc");
}









.screen a {
  display: contents;
  text-decoration: none;
}

.full-width-a {
  width: 100%;
}

.full-height-a {
  height: 100%;
}

.container-center-horizontal {
  display: flex;
  flex-direction: row;
  pointer-events: none;
  width: 100%;
  justify-content: center;
  zoom: 76% !important;
}


.container-center-horizontal > * {
  flex-shrink: 0;
  pointer-events: auto;
}

.align-self-flex-end {
  align-self: flex-end;
}

* {
  box-sizing: border-box;
}

@media (max-width: 1440px) {
  .container-center-horizontal {
    zoom: 70%;
  }
}