.date-6-1 {
  align-items: center !important;
  display: flex;
  height: 18px;
  left: 1px;
  position: absolute;
  top: 26px;
  width: 130px;
}

.calendar-1 {
  background-image: url(/static/img/new-vector-13@1x.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 13.56px;
  margin-bottom: 1.3px;
  width: 15.01px;
}

.date-7 {
  flex: 1;
  letter-spacing: 0;
  line-height: 10px;
  margin-bottom: -2px;
  margin-left: 8.0px;
  margin-right: -2.0px;
}

.date-6-1.date-6 .calendar-1 {
  margin-bottom: 1.4px;
}

.date-6-1.date-12,
.date-6-1.date-14,
.date-6-1.date-16 {
  align-items: flex-end;
  display: flex;
  height: 19px;
  left: 1px;
  position: absolute;
  top: 25px;
  width: 130px;
}

.date-6-1.date-12 .calendar-1 {
  margin-bottom: 1.6px;
}

.date-6-1.date-12 .date-7,
.date-6-1.date-14 .date-7,
.date-6-1.date-16 .date-7 {
  margin-right: -2px;
}

.date-6-1.date-8-1 .calendar-1,
.date-6-1.date-10-1 .calendar-1 {
  width: 15.01px;
}

.date-6-1.date-8 .calendar-1,
.date-6-1.date-10 .calendar-1 {
  margin-bottom: 1.4px;
  width: 15.01px;
}

.date-6-1.date-14 .calendar-1,
.date-6-1.date-16 .calendar-1 {
  margin-bottom: 1.6px;
  width: 15.01px;
}

