.desktop-my-connections-ecwid-social-connect-dashboard {
  align-items: flex-start;
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  /* height: 1421px; */
  overflow: hidden;
  /* width: 1920px; */
}

.group-113-1 {
  display: flex;
  height: 120px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1920px;
}

.nav {
  align-items: center;
  background-color: var(--white);
  display: flex;
  flex: 1;
  justify-content: flex-end;
  min-width: 1920px;
  padding: 0 50px;
  position: relative;
}

.separator-1 {
  background-color: var(--silver-sand);
  border-radius: 8px;
  height: 56px;
  margin-left: 37px;
  opacity: 0.5;
  width: 1px;
}

.profile-5 {
  align-items: center;
  cursor: pointer;
  display: flex;
  margin-bottom: 1.0px;
  margin-left: 30px;
  min-width: 223px;
  position: relative;
}

.overlap-group1-12 {
  align-items: flex-start;
  background-color: var(--silver-sand);
  border-radius: 14px;
  display: flex;
  height: 57px;
  justify-content: flex-end;
  min-width: 57px;
  padding: 15.0px 17.0px;
}

.icon-4 {
  height: 26px;
  object-fit: cover;
  width: 22px;
}

.overlap-group4-5 {
  height: 1455px;
  left: 0;
  position: absolute;
  top: 0;
  width: 345px;
}

.footer-1 {
  background-color: var(--white);
  box-shadow: 18px 4px 70px #00000005;
  height: 1421px;
  left: 0;
  position: absolute;
  top: 0;
  width: 345px;
}

.menus-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 30px;
  min-height: 220px;
  position: absolute;
  top: 120px;
  width: 285px;
}

.x01-4 {
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  height: 50px;
  min-width: 285px;
  padding: 9px 22.3px;
  position: relative;
}
.x01-4-default {
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  height: 50px;
  /* min-width: 285px; */
  padding: 9px 22.3px;
  position: relative;
}

.dashboard-4 {
  align-self: flex-end;
  height: 31px;
  letter-spacing: 0;
  line-height: 18px;
  margin-left: 28px;
  width: 98px;
}

.x02-1 {
  align-items: center;
  background-color: var(--bizarre);
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  height: 50px;
  margin-top: 14px;
  min-width: 285px;
  padding: 9px 25.2px;
}

.proposal-1 {
  background-position: 50% 50%;
  background-size: cover;
  height: 24px;
  width: 18px;
}

.projects-11 {
  align-self: flex-end;
  height: 29px;
  letter-spacing: 0;
  line-height: 18px;
  margin-left: 31px;
  width: 145px;
}

.group-83-1 {
  align-items: flex-end;
  background-color: var(--white);
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  height: 50px;
  margin-top: 6px;
  min-width: 285px;
  padding: 11px 26px;
  position: relative;
}

.group-84-1 {
  align-items: flex-end;
  background-color: var(--white);
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  height: 50px;
  min-width: 285px;
  padding: 11px 26px;
  position: relative;
}

.overlap-group1-13 {
  align-items: flex-end;
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 10px;
  display: flex;
  height: 600px;
  left: 30px;
  min-width: 285px;
  padding: 17.2px 14px;
  position: absolute;
  top: 382px;
}

.button-withdraw-all-earning-10 {
  align-items: flex-start;
  background-color: var(--bizarre);
  border-radius: 10px;
  box-shadow: 2.78px 15.76px 40px #695f970d;
  display: flex;
  height: 40px;
  justify-content: flex-end;
  min-width: 108px;
  padding: 0 9.7px;
}

.learn-more-3 {
  letter-spacing: 0.08px;
  line-height: 49px;
  margin-top: -3.5px;
  min-height: 20px;
  min-width: 86px;
  text-align: center;
  white-space: nowrap;
}

.overlap-group2-8 {
  background-position: 50% 50%;
  background-size: cover;
  height: 224px;
  left: 18px;
  position: absolute;
  top: 1001px;
  width: 309px;
}

.overlap-group1-14 {
  height: 200px;
  left: 27px;
  position: absolute;
  top: 8px;
  width: 270px;
}

.your-image-here-2 {
  height: 191px;
  left: 79px;
  object-fit: cover;
  position: absolute;
  top: 7px;
  width: 191px;
}

.sleepangle-2 {
  height: 200px;
  left: 142px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 128px;
}

.group-25-2 {
  align-items: flex-start;
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: flex-end;
  left: 0;
  min-width: 114px;
  position: absolute;
  top: 147px;
}

.overlap-group-36 {
  align-items: flex-start;
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  height: 160px;
  margin-top: -44.24px;
  min-width: 234px;
  padding: 40.7px 86.0px;
}

.explore-1 {
  letter-spacing: 0.08px;
  line-height: 49px;
  min-height: 20px;
  min-width: 58px;
  text-align: center;
  white-space: nowrap;
}

.ongoing-2 {
  height: 46px;
  left: 0;
  letter-spacing: 0;
  line-height: 12px;
  position: absolute;
  top: 61px;
  width: 114px;
}

.just-in-1 {
  left: 27px;
  letter-spacing: 0;
  line-height: 26px;
  position: absolute;
  top: 34px;
  white-space: nowrap;
}

.overlap-group3-4 {
  background-position: 50% 50%;
  background-size: cover;
  height: 224px;
  left: 18px;
  position: absolute;
  top: 1231px;
  width: 309px;
}

.your-image-here-3 {
  height: 191px;
  left: 106px;
  object-fit: cover;
  position: absolute;
  top: 15px;
  width: 191px;
}

.sleepangle-3 {
  height: 200px;
  left: 169px;
  object-fit: cover;
  position: absolute;
  top: 8px;
  width: 128px;
}

.group-25-3 {
  align-items: flex-start;
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: flex-end;
  left: 24px;
  min-width: 114px;
  position: absolute;
  top: 158px;
}

.overlap-group-37 {
  align-items: flex-start;
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  height: 160px;
  margin-top: -44.24px;
  min-width: 234px;
  padding: 40.7px 80.0px;
}

.subscribe-1 {
  letter-spacing: 0.08px;
  line-height: 49px;
  min-height: 20px;
  min-width: 70px;
  text-align: center;
  white-space: nowrap;
}

.posse-box-1 {
  left: 24px;
  letter-spacing: 0;
  line-height: 26px;
  position: absolute;
  top: 50px;
  white-space: nowrap;
}

.ongoing-3 {
  height: 46px;
  left: 24px;
  letter-spacing: 0;
  line-height: 12px;
  position: absolute;
  top: 84px;
  width: 133px;
}

.group-container-2 {
  /* height: 1439px; */
  left: 0;
  position: absolute;
  top: 0;
  /* width: 1920px; */
}

.overlap-group6-2 {
  width: 100% !important;
  height: 1000px;
  position: absolute;
}

.my-connections {
  left: 320px;
  letter-spacing: 0;
  /* width: 100%; */
  /* line-height: 44px; */
  position: relative;
  /* top: 121px !important; */
  white-space: nowrap;
}
.lateral {
  width: auto;
}

.iframe-control-panel {
  overflow-y: auto;
}

.facebook-panel {
  background-color: #F2DEDB;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  margin: 8px;
  padding: 10px;
  /* margin-left: 63px; */
  width: 78%;
  border-radius: 10px;
  cursor: pointer;
}

.facebook-panel-1 {
  /* background-color: #F2DEDB; */
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  left: 30px;
  margin: 8px;
  padding: 10px;
  /* margin-left: 63px; */
  width: 55%;
  border-radius: 10px;
  cursor: pointer;
}

.instagram-panel {
  background-color: #F2DEDB;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  margin: 8px;
  padding: 10px;
  /* margin-left: 63px; */
  width: 78%;
  border-radius: 10px;
  cursor: pointer;
}

.facebook-image {
  width: 30px;
}

.instagram-image {
  width: 30px;
}

.facebook-text {
  height: 23px !important;
  margin-left: 20px !important;
}

.instagram-text {
  height: 23px !important;
  margin-left: 20px !important;
}

.container-center-horizontal {
  zoom: 76% !important;
  display: inline-block !important;
}

@media (max-width: 1200px) {
  .lateral {
    display: none;
  }
  .my-connections {
    left: 0px !important;
    padding-left: 0px !important;
  }
  .montserrat-bold-black-44px {
    width: 90% !important;
  }
}