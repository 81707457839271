.group-79,
.group-79-1 {
  align-items: center;
  display: flex;
  flex-direction: column;
  left: 428px;
  min-height: 124px;
  position: absolute;
  top: 272px;
  width: 272px;
}

.icon-star,
.path-50 {
  height: 57px;
  margin-right: 2.17px;
  object-fit: cover;
  width: 60px;
}

.x5-per-1-spent,
.x5-per-1-spent-1 {
  letter-spacing: 0;
  line-height: 18px;
  margin-top: 23px;
  min-height: 27px;
  min-width: 156px;
  text-align: center;
  white-space: nowrap;
}

.on-personal-referr,
.on-personal-referr-1 {
  letter-spacing: 0;
  line-height: 12px;
  margin-top: 4px;
  min-height: 18px;
  min-width: 272px;
  white-space: nowrap;
}

