.desktop-top-right-modal {
  align-items: flex-start;
  justify-content: center;
  background-color: var(--hint-of-red);
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  height: 1421px;
  width: 1920px;
}

.overlap-group-4 {
  height: 217px;
  margin-left: 1593px;
  position: relative;
  width: 235px;
}

.imagen-lose {
  width: 150px;
  margin: 50px;
}


.background-modal {
  background-color: var(--white);
  border-radius: 14px;
  box-shadow: 0px 1px 56px #0000001f;
  height: 150px;
  left: auto;
  right: auto;
  position: absolute;
  top: 50px;
  right: 700px;
  width: 300px;
  display: flex;
  padding: 15px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 1.5rem;
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.group-42 {
  cursor: pointer;
  display: flex;
  height: 51px;
  left: 31px;
  position: absolute;
  top: 36px;
  width: 180px;
}

.add-friend-2-4 {
  height: 19px;
  margin-left: 0;
  margin-top: 3.4px;
  object-fit: cover;
  width: 20.01px;
}

.lorem-ipsum {
  flex: 1;
  letter-spacing: 0;
  line-height: 20px;
  margin-bottom: -2px;
  margin-left: 18.0px;
  margin-right: -2px;
}

.group-41 {
  display: flex;
  height: 51px;
  left: 29px;
  position: absolute;
  top: 96px;
  width: 182px;
}

.path-43 {
  height: 25.41px;
  margin-left: 0;
  margin-top: 0.1px;
  object-fit: cover;
  width: 23.05px;
}

.dolor-sit-amet {
  cursor: pointer;
  flex: 1;
  letter-spacing: 0;
  line-height: 20px;
  margin-bottom: -2px;
  margin-left: 17.0px;
  margin-right: -2px;
}

.alert-lose {
  width: 500px !important;
  height: 500px;
}

.message-error {
  font-family: var(--font-family-avenir-black);
}

