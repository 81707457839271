.new-dashboard-1-1 {
  align-items: flex-end;
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  height: 19.83px;
  margin-left: 0;
  margin-top: 3.0px;
  min-width: 23.33px;
  padding: 7.0px 10.5px;
}

.vector {
  height: 2px;
  object-fit: cover;
  width: 2px;
}

.new-dashboard-1-1.new-dashboard-1-3,
.new-dashboard-1-1.new-dashboard-1-4 {
  height: 19.83px;
}

.new-dashboard-1-1.new-dashboard-1-5 {
  background-image: url(/static/img/new-vector-11@1x.png);
  height: 20px;
  margin-bottom: 3.5px;
  margin-left: unset;
  margin-top: unset;
  min-width: 23px;
}

.new-dashboard-1-1.new-dashboard-1-6,
.new-dashboard-1-1.new-dashboard-1-7,
.new-dashboard-1-1.new-dashboard-1-8,
.new-dashboard-1-1.new-dashboard-1-9,
.new-dashboard-1-1.new-dashboard-1-10 {
  background-image: url(/static/img/new-vector-17@1x.png);
  height: 20px;
  margin-left: unset;
  margin-top: unset;
  min-width: 23px;
}

.new-dashboard-1-1.new-dashboard-1-11 {
  background-image: url(/static/img/new-vector-61@1x.png);
  height: 20px;
  margin-left: unset;
  margin-top: 1.0px;
  min-width: 23px;
}

