.containerMain{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 20px;
  width: 80vw;
}
.containerSecond{
  margin: 10px;
  width: 80%;
  display: flex;
  justify-content: left;
  align-items: center;
}
li{
  font-family: "Avenir-Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
}
.containerSecondModalError{
  display: flex;
  justify-content: left;
  align-items: center;
}
.msjError{
  width: 100%;
  display: flex;
  justify-content: right;
  margin-top: 10px;
}
.containerText{
  border: 1px solid #d1d5db;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.inputShareLink{
  padding: 5px;
  border:0;
  margin-left: 2%;
  border-radius: 0px 5px 5px 0px;
  outline: none;
  width: 100%;
  background-color: #EFEFEF;
  font-size: 18px;
}
.labelShareLink{
  padding: 5px;
  margin-left: 2%;
  color: var(--royal-blue);
  font-family: "Avenir-Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;

}
.spanShareLink{
  margin-right: 5px;
  color: var(--eerie-black);
  font-family: var(--font-family-manrope);
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 38px;
  white-space: nowrap;
}
.buttonEdit{
  width: 100px !important;
  height: 30px !important;
  background-color: #f2dedb !important;
  border: none !important;
  border-radius: 5px !important;
  margin-left: 10px !important;
  font-size: 18px !important;
}
.containerButtonEdit{
  margin-left: 5px;
  border-radius: 5px;
  background-color: rgba(242, 222, 219, 1);
  border: 1px rgba(242, 222, 219, 1) solid;
}
.buttonEdit:hover{
  cursor: pointer;
}


.containerCardError{
  border-radius: 5px;
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
}
.containerCardError-v2{
  border-radius: 5px;
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.cabeceraWithButton{
  display: flex;
  justify-content: space-between;
}
.buttonCloseModal:hover{
  cursor: pointer;
}
.bodyCardModalError{
  margin-top: 10px;
}

.textErrorTitleModal{
  font-family: "Avenir-Heavy";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
}
.titleBodyErrorModal{
  font-family: "Avenir-Heavy";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
}
ul{
  margin-top: 10px;
  margin-bottom: 10px;
}

.supportModalError{
  font-family: "Avenir-Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
}



@media only screen and (min-width: 0px) and (max-width: 719px)  {
  .containerMain{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .containerSecond{
    display: block;
    margin: 10px;
    justify-content: center;
    align-items: center;
  }
  .spanShareLink{
    margin-bottom: 5px;
    display: flex;
    justify-content: center;
  }
  .containerButtonEdit{
    margin-top: 10px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
  }
}

/* @media only screen and (min-width: 720px) and (max-width: 1365px)  {

  
}
@media only screen and (min-width: 0px) and (max-width: 374px)  {

} */