.search-2 {
  align-items: flex-start;
  display: flex;
  height: 27.99px;
  margin-left: 38.0px;
  margin-top: 14.0px;
  min-width: 28px;
}

.vector-container {
  height: 28px;
  margin-top: 0;
  position: relative;
  width: 28px;
}

.vector {
  height: 24px;
  left: 4px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 24px;
}

.vector-1 {
  height: 10px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 18px;
  width: 10px;
}

.search-2.search-2-4,
.search-2.search-2-5,
.search-2.search-2-6,
.search-2.search-2-7 {
  align-self: center;
  height: unset;
  margin-bottom: 0;
  margin-left: unset;
  margin-top: unset;
  min-width: 28px;
}

