.group-68-3 {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 0;
  min-height: 44px;
  padding: 0 0.0px;
  width: 31px;
}

.icon-label-4 {
  height: 30px;
  margin-left: 0;
  object-fit: cover;
  width: 31px;
}

.redeem-3 {
  letter-spacing: 0;
  line-height: 4px;
  margin-right: 2.36px;
  margin-top: 10px;
  min-height: 7px;
  min-width: 16px;
  text-align: center;
  text-decoration: underline;
  white-space: nowrap;
}

.group-68-3.group-6 {
  margin-left: 5px;
}

