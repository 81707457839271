:root { 
  --alabaster: #fafafa;
  --bizarre: #f2dedb;
  --black: #000000;
  --black-2: #020202;
  --black-haze: #f6f6f6;
  --cameo: #d8b696;
  --cararra: #ebebeb;
  --cloud: #c7c7c7;
  --cod-gray: #070606;
  --cultured-pearl: #f5f5f5;
  --dove-gray: #707070;
  --eerie-black: #202020;
  --gallery: #efefef;
  --geyser: #d1e0ea;
  --hint-of-red: #f9f9f9;
  --iron: #d8d8d8;
  --log-cabin: #222222;
  --pale-sky: #64778c;
  --quick-silver: #a5a5a5;
  --romance: #fffcfc;
  --royal-blue: #2f5df2;
  --shady-lady: #aaaaaa;
  --silver-sand: #c2c2c2;
  --sonic-silver: #747474;
  --stack: #8f8f8f;
  --surf-crest: #cfe2d9;
  --white: #ffffff;
 

  --new--bizarre: #f2dedb;
  --new--black: #000000;
  --new--black-2: #020202;
  --new--cameo: #d8b696;
  --new--cameo-2: #d6b793;
  --new--cararra: #ebebeb;
  --new--cloud: #c7c7c7;
  --new--cod-gray: #070606;
  --new--dove-gray: #707070;
  --new--eerie-black: #202020;
  --new--geyser: #d1e0ea;
  --new--hint-of-red: #f9f9f9;
  --new--log-cabin: #222222;
  --new--pale-sky: #64778c;
  --new--quick-silver: #a5a5a5;
  --new--silver-sand: #c2c2c2;
  --new--sonic-silver: #747474;
  --new--sonic-silver: #ffffff;
 
  --new--font-size-l: 18px;
  --new--font-size-m: 16px;
  --new--font-size-s: 14px;
  --new--font-size-xl: 20px;
  --new--font-size-xs: 12px;
  --new--font-size-xxs: 10px;
  --new--font-size-xxxs: 7px;
 
  --new--font-family-avenir: "Avenir", Helvetica;
  --new--font-family-avenir-black: "Avenir-Black", Helvetica;
  --new--font-family-avenir-book: "Avenir-Book", Helvetica;
  --new--font-family-avenir-heavy: "Avenir-Heavy", Helvetica;
  --new--font-family-avenir-heavyoblique: "Avenir-HeavyOblique", Helvetica;
  --new--font-family-avenir-medium: "Avenir-Medium", Helvetica;
  --new--font-family-brittanysignature-regular: "BrittanySignature-Regular", Helvetica;
  --new--font-family-manrope: "Manrope", Helvetica;
  --new--font-family-montserrat: "Montserrat", Helvetica;
  --new--font-family-open_sans: "Open Sans", Helvetica;
  --new--font-family-poppins: "Poppins", Helvetica;

  --cloud: #c7c7c7;
  --eerie-black: #202020;
  --quick-silver: #a5a5a5;
 
  --font-size-xl2: 14px;
  --font-size-xxxl2: 16px;
  --font-size-xxxxl2: 18px;
  --font-size-xxxxxl: 20px;
 
  --font-family-manrope: "Manrope", Helvetica;
  --font-family-open_sans: "Open Sans", Helvetica;

  --font-size-l: 14px;
  --font-size-m: 12px;
  --font-size-s: 10px;
  --font-size-xl: 15px;
  --font-size-xxl: 16px;
  --font-size-xxxl: 18px;
  --font-size-xxxxl: 20px;
 
  --font-family-avenir-black: "Avenir-Black", Helvetica;
  --font-family-avenir-book: "Avenir-Book", Helvetica;
  --font-family-avenir-heavy: "Avenir-Heavy", Helvetica;
  --font-family-avenir-medium: "Avenir-Medium", Helvetica;
  --font-family-bodoni72-bold: "Bodoni72-Bold", Helvetica;
  --font-family-cairo: "Cairo", Helvetica;
  --font-family-manrope: "Manrope", Helvetica;
  --font-family-montserrat: "Montserrat", Helvetica;
  --font-family-open_sans: "Open Sans", Helvetica;
  --font-family-poppins: "Poppins", Helvetica;
}
.avenir-black-eerie-black-16px {
  color: var(--eerie-black);
  font-family: var(--font-family-avenir-black);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 900;
}

.avenir-heavy-normal-eerie-black-20px {
  color: var(--eerie-black);
  font-family: var(--font-family-avenir-heavy);
  font-size: var(--font-size-xxxxl);
  font-style: normal;
  font-weight: 400;
}

.manrope-normal-royal-blue-14px {
  color: var(--royal-blue);
  font-family: var(--font-family-manrope);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 400;
}

.avenir-medium-black-18px {
  color: var(--black-2);
  font-family: var(--font-family-avenir-medium);
  font-size: var(--font-size-xxxl);
  font-style: normal;
  font-weight: 500;
}

.manrope-normal-eerie-black-12px {
  color: var(--eerie-black);
  font-family: var(--font-family-manrope);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.avenir-book-normal-black-12px {
  color: var(--black-2);
  font-family: var(--font-family-avenir-book);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.manrope-normal-quick-silver-10px {
  color: var(--quick-silver);
  font-family: var(--font-family-manrope);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 400;
}

.montserrat-bold-cloud-16px {
  color: var(--cloud);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 700;
}

.manrope-bold-black-13px {
  color: var(--black);
  font-family: var(--font-family-manrope);
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
}

.avenir-book-normal-white-13px {
  color: var(--white);
  font-family: var(--font-family-avenir-book);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
}

.bodoni72-bold-eerie-black-24px {
  color: var(--eerie-black);
  font-family: var(--font-family-bodoni72-bold);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
}

.cairo-semi-bold-shady-lady-16px {
  color: var(--shady-lady);
  font-family: var(--font-family-cairo);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 600;
}

.avenir-heavy-normal-eerie-black-18px {
  color: var(--eerie-black);
  font-family: var(--font-family-avenir-heavy);
  font-size: var(--font-size-xxxl);
  font-style: normal;
  font-weight: 400;
}

.avenir-book-normal-sonic-silver-14px {
  color: var(--sonic-silver);
  font-family: var(--font-family-avenir-book);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 400;
}

.avenir-heavy-normal-sonic-silver-14px {
  color: var(--sonic-silver);
  font-family: var(--font-family-avenir-heavy);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 400;
}

.manrope-normal-quick-silver-18px {
  color: var(--quick-silver);
  font-family: var(--font-family-manrope);
  font-size: var(--font-size-xxxl);
  font-style: normal;
  font-weight: 400;
}

.cairo-semi-bold-eerie-black-20px {
  color: var(--eerie-black);
  font-family: var(--font-family-cairo);
  font-size: var(--font-size-xxxxl);
  font-style: normal;
  font-weight: 600;
}

.avenir-medium-cod-gray-18px {
  color: var(--cod-gray);
  font-family: var(--font-family-avenir-medium);
  font-size: var(--font-size-xxxl);
  font-style: normal;
  font-weight: 500;
}

.manrope-extra-bold-bizarre-10px {
  color: var(--bizarre);
  font-family: var(--font-family-manrope);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 800;
}

.manrope-extra-bold-geyser-10px {
  color: var(--geyser);
  font-family: var(--font-family-manrope);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 800;
}

.avenir-heavy-normal-eerie-black-10px {
  color: var(--eerie-black);
  font-family: var(--font-family-avenir-heavy);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 400;
}

.avenir-book-normal-log-cabin-20px {
  color: var(--log-cabin);
  font-family: var(--font-family-avenir-book);
  font-size: var(--font-size-xxxxl);
  font-style: normal;
  font-weight: 400;
}

.avenir-heavy-normal-eerie-black-14px {
  color: var(--eerie-black);
  font-family: var(--font-family-avenir-heavy);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 400;
}

.avenir-book-normal-eerie-black-12px {
  color: var(--eerie-black);
  font-family: var(--font-family-avenir-book);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.avenir-heavy-normal-white-24px {
  color: #000000 !important; 
  font-family: var(--font-family-avenir-heavy);
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
}

.avenir-heavy-normal-sonic-silver-16px {
  color: var(--sonic-silver);
  font-family: var(--font-family-avenir-heavy);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 400;
}

.poppins-medium-eerie-black-18px {
  color: var(--eerie-black);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xxxl);
  font-style: normal;
  font-weight: 500;
}

.manrope-bold-eerie-black-20px {
  color: var(--eerie-black);
  font-family: var(--font-family-manrope);
  font-size: var(--font-size-xxxxl);
  font-style: normal;
  font-weight: 700;
}

.avenir-book-normal-stack-14px {
  color: var(--stack);
  font-family: var(--font-family-avenir-book);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 400;
}

.avenir-medium-black-13px {
  color: var(--black);
  font-family: var(--font-family-avenir-medium);
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
}

.avenir-heavy-normal-white-11px {
  color: var(--white);
  font-family: var(--font-family-avenir-heavy);
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
}

.avenir-medium-quick-silver-15px {
  color: var(--quick-silver);
  font-family: var(--font-family-avenir-medium);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 500;
}

.montserrat-bold-eerie-black-34px {
  color: var(--eerie-black);
  font-family: var(--font-family-montserrat);
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
}

.manrope-semi-bold-quick-silver-20px {
  color: var(--quick-silver);
  font-family: var(--font-family-manrope);
  font-size: var(--font-size-xxxxl);
  font-style: normal;
  font-weight: 600;
}

.avenir-medium-black-14px {
  color: var(--black);
  font-family: var(--font-family-avenir-medium);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 500;
}

.avenir-heavy-normal-white-14px {
  color: var(--white);
  font-family: var(--font-family-avenir-heavy);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 400;
}

.avenir-heavy-normal-pale-sky-14px {
  color: var(--pale-sky);
  font-family: var(--font-family-avenir-heavy);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 400;
}

.opensans-bold-cloud-16px {
  color: var(--cloud);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 700;
}

.avenir-book-normal-sonic-silver-11px {
  color: var(--sonic-silver);
  font-family: var(--font-family-avenir-book);
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
}

.avenir-heavy-normal-sonic-silver-11px {
  color: var(--sonic-silver);
  font-family: var(--font-family-avenir-heavy);
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
}

.avenir-book-normal-sonic-silver-4px {
  color: var(--sonic-silver);
  font-family: var(--font-family-avenir-book);
  font-size: 4px;
  font-style: normal;
  font-weight: 400;
}

.avenir-heavy-normal-sonic-silver-5px {
  color: var(--sonic-silver);
  font-family: var(--font-family-avenir-heavy);
  font-size: 5px;
  font-style: normal;
  font-weight: 400;
}

.manrope-normal-eerie-black-16px {
  color: var(--eerie-black);
  font-family: var(--font-family-manrope);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 400;
}

.manrope-semi-bold-eerie-black-16px {
  color: var(--eerie-black);
  font-family: var(--font-family-manrope);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 600;
}

.avenir-black-black-18px {
  color: var(--black);
  font-family: var(--font-family-avenir-black);
  font-size: var(--font-size-xxxl);
  font-style: normal;
  font-weight: 900;
}

.manrope-bold-cod-gray-14px {
  color: var(--cod-gray);
  font-family: var(--font-family-manrope);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 700;
}

.avenir-black-eerie-black-24px {
  color: var(--eerie-black);
  font-family: var(--font-family-avenir-black);
  font-size: 24px;
  font-style: normal;
  font-weight: 900;
}

.montserrat-medium-quick-silver-16px {
  color: var(--quick-silver);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 500;
}

.montserrat-bold-black-44px {
  color: var(--black);
  font-family: var(--font-family-montserrat);
  font-size: 44px;
  font-style: normal;
  font-weight: 700;
  width: 80%;
}

.avenir-book-normal-eerie-black-6px {
  color: var(--eerie-black);
  font-family: var(--font-family-avenir-book);
  font-size: 6px;
  font-style: normal;
  font-weight: 400;
}

.avenir-book-normal-log-cabin-10px {
  color: var(--log-cabin);
  font-family: var(--font-family-avenir-book);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 400;
}

.avenir-heavy-normal-eerie-black-7px {
  color: var(--eerie-black);
  font-family: var(--font-family-avenir-heavy);
  font-size: 7px;
  font-style: normal;
  font-weight: 400;
}

.avenir-heavy-normal-white-19px {
  color: var(--white);
  font-family: var(--font-family-avenir-heavy);
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
}

.avenir-heavy-normal-sonic-silver-12px {
  color: var(--sonic-silver);
  font-family: var(--font-family-avenir-heavy);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.montserrat-bold-black-34px {
  color: var(--black);
  font-family: var(--font-family-montserrat);
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
}

.avenir-heavy-normal-eerie-black-9px {
  color: var(--eerie-black);
  font-family: var(--font-family-avenir-heavy);
  font-size: 9px;
  font-style: normal;
  font-weight: 400;
}

.avenir-book-normal-eerie-black-4px {
  color: var(--eerie-black);
  font-family: var(--font-family-avenir-book);
  font-size: 4px;
  font-style: normal;
  font-weight: 400;
}

.avenir-book-normal-log-cabin-6px {
  color: var(--log-cabin);
  font-family: var(--font-family-avenir-book);
  font-size: 6px;
  font-style: normal;
  font-weight: 400;
}

.avenir-heavy-normal-eerie-black-4px {
  color: var(--eerie-black);
  font-family: var(--font-family-avenir-heavy);
  font-size: 4px;
  font-style: normal;
  font-weight: 400;
}

.avenir-heavy-normal-sonic-silver-7px {
  color: var(--sonic-silver);
  font-family: var(--font-family-avenir-heavy);
  font-size: 7px;
  font-style: normal;
  font-weight: 400;
}

.montserrat-bold-black-24px {
  color: var(--black);
  font-family: var(--font-family-montserrat);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
}

.manrope-normal-quick-silver-12px {
  color: var(--quick-silver);
  font-family: var(--font-family-manrope);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.manrope-normal-eerie-black-10px {
  color: var(--eerie-black);
  font-family: var(--font-family-manrope);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 400;
}

.avenir-book-normal-black-15px {
  color: var(--black);
  font-family: var(--font-family-avenir-book);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 400;
}

.avenir-book-normal-quick-silver-18px {
  color: var(--quick-silver);
  font-family: var(--font-family-avenir-book);
  font-size: var(--font-size-xxxl);
  font-style: normal;
  font-weight: 400;
}

.avenir-medium-black-19px {
  color: var(--black);
  font-family: var(--font-family-avenir-medium);
  font-size: 19px;
  font-style: normal;
  font-weight: 500;
}

.bodoni72-bold-black-72px {
  color: var(--black);
  font-family: var(--font-family-bodoni72-bold);
  font-size: 72px;
  font-style: normal;
  font-weight: 700;
}

.avenir-heavy-normal-white-32px {
  color: var(--white);
  font-family: var(--font-family-avenir-heavy);
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
}

.avenir-book-normal-black-20px {
  color: var(--black);
  font-family: var(--font-family-avenir-book);
  font-size: var(--font-size-xxxxl);
  font-style: normal;
  font-weight: 400;
}

.bodoni72-bold-black-60px {
  color: var(--black);
  font-family: var(--font-family-bodoni72-bold);
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
}

.montserrat-normal-quick-silver-15px {
  color: var(--quick-silver);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 400;
}

.avenir-heavy-normal-eerie-black-24px {
  color: var(--eerie-black);
  font-family: var(--font-family-avenir-heavy);
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
}

.avenir-medium-eerie-black-12px {
  color: var(--eerie-black);
  font-family: var(--font-family-avenir-medium);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 500;
}

.opensans-normal-quick-silver-12px {
  color: var(--quick-silver);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.avenir-medium-black-10px {
  color: var(--black);
  font-family: var(--font-family-avenir-medium);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 500;
}

.avenir-medium-cod-gray-10px {
  color: var(--cod-gray);
  font-family: var(--font-family-avenir-medium);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 500;
}

.avenir-heavy-normal-eerie-black-12px {
  color: var(--eerie-black);
  font-family: var(--font-family-avenir-heavy);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.avenir-medium-black-7px {
  color: var(--black);
  font-family: var(--font-family-avenir-medium);
  font-size: 7px;
  font-style: normal;
  font-weight: 500;
}

.bodoni72-bold-eerie-black-22px {
  color: var(--eerie-black);
  font-family: var(--font-family-bodoni72-bold);
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
}

.bodoni72-bold-black-43px {
  color: var(--black);
  font-family: var(--font-family-bodoni72-bold);
  font-size: 43px;
  font-style: normal;
  font-weight: 700;
}

.avenir-heavy-normal-pale-sky-11px {
  color: var(--pale-sky);
  font-family: var(--font-family-avenir-heavy);
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
}

.avenir-medium-black-4px {
  color: var(--black);
  font-family: var(--font-family-avenir-medium);
  font-size: 4px;
  font-style: normal;
  font-weight: 500;
}

.avenir-heavy-normal-white-4px {
  color: var(--white);
  font-family: var(--font-family-avenir-heavy);
  font-size: 4px;
  font-style: normal;
  font-weight: 400;
}

.avenir-heavy-normal-pale-sky-4px {
  color: var(--pale-sky);
  font-family: var(--font-family-avenir-heavy);
  font-size: 4px;
  font-style: normal;
  font-weight: 400;
}

.bodoni72-bold-eerie-black-32px {
  color: var(--eerie-black);
  font-family: var(--font-family-bodoni72-bold);
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
}

.avenir-book-normal-white-15px {
  color: var(--white);
  font-family: var(--font-family-avenir-book);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 400;
}

.poppins-bold-white-18px {
  color: var(--white);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xxxl);
  font-style: normal;
  font-weight: 700;
}

.border-2px-dove-gray {
  border: 2px solid var(--dove-gray);
}

.border-1px-quick-silver {
  border: 1px solid var(--quick-silver);
}

.border-1px-cararra {
  border: 1px solid var(--cararra);
}

.border-0-5px-dove-gray {
  border: 0.5px solid var(--dove-gray);
}

.border-1px-silver-sand {
  border: 1px solid var(--silver-sand);
}

.border-1px-dove-gray {
  border: 1px solid var(--dove-gray);
}





.new-avenir-medium-black-18px {
  color: var(--new--black-2);
  font-family: var(--new--font-family-avenir-medium);
  font-size: var(--new--font-size-l);
  font-style: normal;
  font-weight: 500;
}

.new-avenir-heavy-normal-sonic-silver-14px {
  color: #000;
  font-family: var(--new--font-family-avenir-heavy);
  font-size: var(--new--font-size-s);
  font-style: normal;
  font-weight: 400;
  margin-left: -27px;
}

.new-manrope-normal-eerie-black-12px {
  color: var(--new--eerie-black);
  font-family: var(--new--font-family-manrope);
  font-size: var(--new--font-size-xs);
  font-style: normal;
  font-weight: 400;
}

.new-manrope-normal-quick-silver-10px {
  color: var(--new--quick-silver);
  font-family: var(--new--font-family-manrope);
  font-size: var(--new--font-size-xxs);
  font-style: normal;
  font-weight: 400;
}

.new-avenir-black-eerie-black-16px {
  color: var(--new--eerie-black);
  font-family: var(--new--font-family-avenir-black);
  font-size: var(--new--font-size-m);
  font-style: normal;
  font-weight: 900;
}

.new-poppins-medium-eerie-black-18px {
  color: var(--new--eerie-black);
  font-family: var(--new--font-family-poppins);
  font-size: var(--new--font-size-l);
  font-style: normal;
  font-weight: 500;
}

.new-avenir-heavy-normal-eerie-black-20px {
  color: var(--new--eerie-black);
  font-family: var(--new--font-family-avenir-heavy);
  font-size: var(--new--font-size-xl);
  font-style: normal;
  font-weight: 400;
}

.new-avenir-heavy-normal-sonic-silver-7px {
  color: var(--new--sonic-silver);
  font-family: var(--new--font-family-avenir-heavy);
  font-size: var(--new--font-size-xxxs);
  font-style: normal;
  font-weight: 400;
}

.new-manrope-extra-bold-bizarre-10px {
  color: var(--new--bizarre);
  font-family: var(--new--font-family-manrope);
  font-size: var(--new--font-size-xxs);
  font-style: normal;
  font-weight: 800;
}

.new-manrope-extra-bold-geyser-10px {
  color: var(--new--geyser);
  font-family: var(--new--font-family-manrope);
  font-size: var(--new--font-size-xxs);
  font-style: normal;
  font-weight: 800;
}

.new-avenir-heavy-normal-white-20px {
  color: var(--new--sonic-silver);
  font-family: var(--new--font-family-avenir-heavy);
  font-size: var(--new--font-size-xl);
  font-style: normal;
  font-weight: 400;
}

.new-avenir-medium-cod-gray-18px {
  color: var(--new--cod-gray);
  font-family: var(--new--font-family-avenir-medium);
  font-size: var(--new--font-size-l);
  font-style: normal;
  font-weight: 500;
  cursor: pointer;
}

.new-avenir-medium-black-18px-2 {
  color: var(--new--black);
  font-family: var(--new--font-family-avenir-medium);
  font-size: var(--new--font-size-l);
  font-style: normal;
  font-weight: 500;
}

.new-avenir-medium-black-15px {
  color: var(--new--black);
  font-family: var(--new--font-family-avenir-medium);
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
}

.new-avenir-medium-eerie-black-12px {
  color: var(--new--eerie-black);
  font-family: var(--new--font-family-avenir-medium);
  font-size: var(--new--font-size-xs);
  font-style: normal;
  font-weight: 500;
}

.new-brittanysignature-regular-normal-geyser-48px {
  color: var(--new--geyser);
  font-family: var(--new--font-family-brittanysignature-regular);
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
}

.new-manrope-normal-quick-silver-18px {
  color: var(--new--quick-silver);
  font-family: var(--new--font-family-manrope);
  font-size: var(--new--font-size-l);
  font-style: normal;
  font-weight: 400;
}

.new-opensans-bold-cloud-16px {
  color: var(--new--cloud);
  font-family: var(--new--font-family-open_sans);
  font-size: var(--new--font-size-m);
  font-style: normal;
  font-weight: 700;
}

.new-avenir-heavy-normal-white-24px {
  color: var(--new--sonic-silver);
  font-family: var(--new--font-family-avenir-heavy);
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
}

.new-avenir-heavy-normal-sonic-silver-16px {
  color: var(--new--sonic-silver);
  font-family: var(--new--font-family-avenir-heavy);
  font-size: var(--new--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.new-avenir-medium-sonic-silver-14px {
  color: var(--new--sonic-silver);
  font-family: var(--new--font-family-avenir);
  font-size: var(--new--font-size-s);
}

.new-avenir-medium-sonic-silver-14px-2 {
  color: #000;
  font-family: var(--new--font-family-avenir-medium);
  font-size: var(--new--font-size-s);
  font-style: normal;
  font-weight: 500;
}

.new-avenir-heavy-normal-pale-sky-20px {
  color: var(--new--pale-sky);
  font-family: var(--new--font-family-avenir-heavy);
  font-size: var(--new--font-size-xl);
  font-style: normal;
  font-weight: 400;
}

.new-avenir-heavy-normal-black-16px {
  color: var(--new--black);
  font-family: var(--new--font-family-avenir-heavy);
  font-size: var(--new--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.new-montserrat-bold-black-44px {
  color: var(--new--black);
  font-family: var(--new--font-family-montserrat);
  font-size: 44px;
  font-style: normal;
  font-weight: 700;
}

.new-montserrat-bold-black-34px {
  color: var(--new--black);
  font-family: var(--new--font-family-montserrat);
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
}

.new-opensans-semi-bold-eerie-black-18px {
  color: var(--new--eerie-black);
  font-family: var(--new--font-family-open_sans);
  font-size: var(--new--font-size-l);
  font-style: normal;
  font-weight: 600;
}

.new-montserrat-bold-black-24px {
  color: var(--new--black);
  font-family: var(--new--font-family-montserrat);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
}

.new-poppins-bold-white-18px {
  color: var(--new--sonic-silver);
  font-family: var(--new--font-family-poppins);
  font-size: var(--new--font-size-l);
  font-style: normal;
  font-weight: 700;
}

.new-avenir-heavy-normal-white-10px {
  color: var(--new--sonic-silver);
  font-family: var(--new--font-family-avenir-heavy);
  font-size: var(--new--font-size-xxs);
  font-style: normal;
  font-weight: 400;
}

.new-avenir-medium-sonic-silver-7px {
  color: var(--new--sonic-silver);
  font-family: var(--new--font-family-avenir-medium);
  font-size: var(--new--font-size-xxxs);
  font-style: normal;
  font-weight: 500;
}

.new-avenir-black-eerie-black-24px {
  color: var(--new--eerie-black);
  font-family: var(--new--font-family-avenir-black);
  font-size: 24px;
  font-style: normal;
  font-weight: 900;
}

.new-montserrat-medium-quick-silver-16px {
  color: var(--new--quick-silver);
  font-family: var(--new--font-family-montserrat);
  font-size: var(--new--font-size-m);
  font-style: normal;
  font-weight: 500;
}

.new-avenir-medium-log-cabin-20px {
  color: var(--new--log-cabin);
  font-family: var(--new--font-family-avenir-medium);
  font-size: var(--new--font-size-xl);
  font-style: normal;
  font-weight: 500;
}

.new-avenir-medium-black-50px {
  color: var(--new--black);
  font-family: var(--new--font-family-avenir-medium);
  font-size: 50px;
  font-style: normal;
  font-weight: 500;
}

.new-avenir-heavy-normal-eerie-black-18px {
  color: var(--new--eerie-black);
  font-family: var(--new--font-family-avenir-heavy);
  font-size: var(--new--font-size-l);
  font-style: normal;
  font-weight: 400;
}

.new-montserrat-bold-eerie-black-34px {
  color: var(--new--eerie-black);
  font-family: var(--new--font-family-montserrat);
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
}

.new-avenir-medium-eerie-black-24px {
  color: var(--new--eerie-black);
  font-family: var(--new--font-family-avenir-medium);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
}

.new-avenir-medium-eerie-black-16px {
  color: var(--new--eerie-black);
  font-family: var(--new--font-family-avenir-medium);
  font-size: var(--new--font-size-m);
  font-style: normal;
  font-weight: 500;
}

.new-avenir-heavy-normal-black-12px {
  color: var(--new--black);
  font-family: var(--new--font-family-avenir-heavy);
  font-size: var(--new--font-size-xs);
  font-style: normal;
  font-weight: 400;
}

.new-avenir-medium-log-cabin-9px {
  color: var(--new--log-cabin);
  font-family: var(--new--font-family-avenir-medium);
  font-size: 9px;
  font-style: normal;
  font-weight: 500;
}

.new-avenir-heavy-normal-black-7px {
  color: var(--new--black);
  font-family: var(--new--font-family-avenir-heavy);
  font-size: var(--new--font-size-xxxs);
  font-style: normal;
  font-weight: 400;
}

.new-avenir-heavy-normal-eerie-black-12px {
  color: var(--new--eerie-black);
  font-family: var(--new--font-family-avenir-heavy);
  font-size: var(--new--font-size-xs);
  font-style: normal;
  font-weight: 400;
}

.new-manrope-normal-quick-silver-12px {
  color: var(--new--quick-silver);
  font-family: var(--new--font-family-manrope);
  font-size: var(--new--font-size-xs);
  font-style: normal;
  font-weight: 400;
}

.new-avenir-book-normal-sonic-silver-14px {
  color: var(--new--sonic-silver);
  font-family: var(--new--font-family-avenir-book);
  font-size: var(--new--font-size-s);
  font-style: normal;
  font-weight: 400;
}

.new-avenir-heavyoblique-normal-eerie-black-14px {
  color: var(--new--eerie-black);
  font-family: var(--new--font-family-avenir-heavyoblique);
  font-size: var(--new--font-size-s);
  font-style: normal;
  font-weight: 400;
}

.new-avenir-book-normal-eerie-black-12px {
  color: var(--new--eerie-black);
  font-family: var(--new--font-family-avenir-book);
  font-size: var(--new--font-size-xs);
  font-style: normal;
  font-weight: 400;
}

.new-avenir-book-normal-black-18px {
  color: var(--new--black);
  font-family: var(--new--font-family-avenir-book);
  font-size: var(--new--font-size-l);
  font-style: normal;
  font-weight: 400;
}

.new-opensans-semi-bold-eerie-black-25px {
  color: var(--new--eerie-black);
  font-family: var(--new--font-family-open_sans);
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
}

.new-avenir-book-normal-black-14px {
  color: var(--new--black);
  font-family: var(--new--font-family-avenir-book);
  font-size: var(--new--font-size-s);
  font-style: normal;
  font-weight: 400;
}

.new-avenir-heavy-normal-eerie-black-14px {
  color: var(--new--eerie-black);
  font-family: var(--new--font-family-avenir-heavy);
  font-size: var(--new--font-size-s);
  font-style: normal;
  font-weight: 400;
}

.new-avenir-book-normal-eerie-black-11px {
  color: var(--new--eerie-black);
  font-family: var(--new--font-family-avenir-book);
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
}

.new-avenir-medium-eerie-black-9px {
  color: var(--new--eerie-black);
  font-family: var(--new--font-family-avenir-medium);
  font-size: 9px;
  font-style: normal;
  font-weight: 500;
}

.new-avenir-heavy-normal-white-16px {
  color: var(--new--sonic-silver);
  font-family: var(--new--font-family-avenir-heavy);
  font-size: var(--new--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.new-avenir-heavyoblique-normal-eerie-black-11px {
  color: var(--new--eerie-black);
  font-family: var(--new--font-family-avenir-heavyoblique);
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
}

.new-avenir-medium-log-cabin-15px {
  color: var(--new--log-cabin);
  font-family: var(--new--font-family-avenir-medium);
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
}

.new-avenir-medium-black-41px {
  color: var(--new--black);
  font-family: var(--new--font-family-avenir-medium);
  font-size: 41px;
  font-style: normal;
  font-weight: 500;
}

.new-opensans-bold-black-16px {
  color: var(--new--black);
  font-family: var(--new--font-family-open_sans);
  font-size: var(--new--font-size-m);
  font-style: normal;
  font-weight: 700;
}

.new-manrope-normal-quick-silver-14px {
  color: var(--new--quick-silver);
  font-family: var(--new--font-family-manrope);
  font-size: var(--new--font-size-s);
  font-style: normal;
  font-weight: 400;
}

.new-avenir-heavy-normal-eerie-black-11px {
  color: var(--new--eerie-black);
  font-family: var(--new--font-family-avenir-heavy);
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
}

.new-avenir-book-normal-eerie-black-8px {
  color: var(--new--eerie-black);
  font-family: var(--new--font-family-avenir-book);
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
}

.new-avenir-medium-eerie-black-7px {
  color: var(--new--eerie-black);
  font-family: var(--new--font-family-avenir-medium);
  font-size: var(--new--font-size-xxxs);
  font-style: normal;
  font-weight: 500;
}

.new-avenir-heavyoblique-normal-eerie-black-7px {
  color: var(--new--eerie-black);
  font-family: var(--new--font-family-avenir-heavyoblique);
  font-size: var(--new--font-size-xxxs);
  font-style: normal;
  font-weight: 400;
}

.new-avenir-heavy-normal-white-13px {
  color: var(--new--sonic-silver);
  font-family: var(--new--font-family-avenir-heavy);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
}

.new-avenir-heavy-normal-sonic-silver-9px {
  color: var(--new--sonic-silver);
  font-family: var(--new--font-family-avenir-heavy);
  font-size: 9px;
  font-style: normal;
  font-weight: 400;
}

.new-avenir-medium-sonic-silver-7px-2 {
  color: var(--new--sonic-silver);
  font-family: var(--new--font-family-avenir);
  font-size: var(--new--font-size-xxxs);
}

.new-avenir-book-normal-sonic-silver-7px {
  color: var(--new--sonic-silver);
  font-family: var(--new--font-family-avenir-book);
  font-size: var(--new--font-size-xxxs);
  font-style: normal;
  font-weight: 400;
}

.new-avenir-heavy-normal-pale-sky-10px {
  color: var(--new--pale-sky);
  font-family: var(--new--font-family-avenir-heavy);
  font-size: var(--new--font-size-xxs);
  font-style: normal;
  font-weight: 400;
}

.new-border-2px-dove-gray {
  border: 2px solid var(--new--dove-gray);
}

.new-border-1px-silver-sand {
  border: 1px solid var(--new--silver-sand);
}

.new-border-1px-dove-gray {
  border: 1px solid var(--new--dove-gray);
}

.new-border-1px-cararra {
  border: 1px solid var(--new--cararra);
}

.new-border-0-5px-dove-gray {
  border: 0.5px solid var(--new--dove-gray);
}







