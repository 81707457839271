.group-80,
.group-80-1 {
  align-items: center;
  display: flex;
  flex-direction: column;
  left: 826px;
  min-height: 124px;
  position: absolute;
  top: 272px;
  width: 182px;
}

.group-64,
.group-64-1 {
  align-items: flex-start;
  display: flex;
  margin-right: 2.04px;
  min-width: 46px;
}

.path-container-3,
.path-container-7 {
  height: 57px;
  margin-top: 0;
  position: relative;
  width: 46px;
}

.path-51,
.path-51-1 {
  height: 24px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 33px;
  width: 22px;
}

.path-52,
.path-52-1 {
  height: 24px;
  left: 24px;
  object-fit: cover;
  position: absolute;
  top: 33px;
  width: 22px;
}

.path-53,
.path-53-1 {
  height: 19px;
  left: 13px;
  object-fit: cover;
  position: absolute;
  top: 10px;
  width: 19px;
}

.path-54,
.path-54-1 {
  height: 40px;
  left: 3px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 39px;
}

.bonus-rewards,
.bonus-rewards-1 {
  letter-spacing: 0;
  line-height: 18px;
  margin-top: 23px;
  min-height: 27px;
  min-width: 160px;
  text-align: center;
  white-space: nowrap;
}

.for-every-200-in-purchases,
.for-every-200-in-purchases-1 {
  letter-spacing: 0;
  line-height: 12px;
  margin-top: 4px;
  min-height: 18px;
  min-width: 182px;
  text-align: center;
  white-space: nowrap;
}

