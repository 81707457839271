.active-8 {
  display: flex;
  height: 85px;
  margin-top: 16px;
  width: 301px;
}

.input-8 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 100%;
  width: 301.49px;
}

.input-box-8 {
  height: 24px;
  letter-spacing: 1.00px;
  line-height: 16px;
  margin-left: 0.71px;
  width: 151px;
}

.overlap-group-61 {
  align-items: flex-start;
  border-radius: 14px;
  display: flex;
  height: 60px;
  margin-top: 1px;
  min-width: 301px;
  padding: 10px 21.0px;
}

.input-here-14 {
  height: 39px;
  letter-spacing: 0;
  line-height: 20px;
  width: 156px;
}

.active-8.active-9 {
  margin-top: 12px;
}

