.tablet-menu {
  align-items: flex-start;
  background-color: var(--hint-of-red);
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  width: 1024px;
}

.overlap-group1-23 {
  background-position: 50% 50%;
  background-size: cover;
  height: 1366px;
  position: relative;
  width: 1024px;
}

.background-6 {
  background-color: var(--white);
  border-radius: 14px;
  box-shadow: 0px 1px 56px #0000001f;
  height: 203px;
  left: 762px;
  position: absolute;
  top: 124px;
  transform: rotate(180deg);
  width: 235px;
}

.background-7 {
  height: 70px;
  left: 794px;
  object-fit: cover;
  position: absolute;
  top: 257px;
  width: 203px;
}

.arrow-11 {
  background-color: var(--white);
  height: 23px;
  left: 942px;
  position: absolute;
  top: 115px;
  transform: rotate(225deg);
  width: 23px;
}

.group-38 {
  cursor: pointer;
  display: flex;
  height: 51px;
  left: 793px;
  position: absolute;
  top: 146px;
  width: 180px;
}

.add-friend-2-5 {
  height: 19px;
  margin-left: 0;
  margin-top: 3.4px;
  object-fit: cover;
  width: 20.01px;
}

.lorem-ipsum-1 {
  flex: 1;
  letter-spacing: 0;
  line-height: 20px;
  margin-bottom: -2px;
  margin-left: 18.0px;
  margin-right: -2px;
}

.group-43 {
  display: flex;
  height: 51px;
  left: 793px;
  position: absolute;
  top: 206px;
  width: 180px;
}

.path-43-1 {
  height: 25.41px;
  margin-left: 0;
  margin-top: 0;
  object-fit: cover;
  width: 23.05px;
}

.dolor-sit-amet-1 {
  cursor: pointer;
  flex: 1;
  letter-spacing: 0;
  line-height: 20px;
  margin-bottom: -2px;
  margin-left: 15.0px;
  margin-right: -2px;
}

