.button-withdraw-all-earning-6 {
  align-items: flex-start;
  background-color: var(--bizarre);
  border-radius: 10px;
  box-shadow: 2.78px 15.76px 40px #695f970d;
  display: flex;
  height: 35px;
  justify-content: flex-end;
  left: 0;
  min-width: 110px;
  padding: 1.9px 14.0px;
  position: absolute;
  top: 88px;
}

.shop-now-1 {
  letter-spacing: 0.08px;
  line-height: 30px;
  min-height: 21px;
  min-width: 80px;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
}

.button-withdraw-all-earning-6.button-withdraw-all-earning-7,
.button-withdraw-all-earning-6.button-withdraw-all-earning-9 {
  align-self: center;
  left: unset;
  margin-right: 0;
  margin-top: 20px;
  position: unset;
  top: unset;
}

